import React from 'react';
import './statistics-widget.css';

type Props = {
  numbers: {
    offen: number,
    abgeschlossen: number,
    insgesamt: number,
  },
};

const StatisticsWidget = ({ numbers }: Props): JSX.Element => (
  <div className="col-12 row dashboard-widget d-flex justify-content-center justify-content-xl-start">
    <div className="pt-5 pt-lg-0 col-12 col-lg-6 col-xl-4">
      <div className="widget-square" id="offene">
        <div className="square-header">Offene Fälle</div>
        <div className="square-content">{numbers.offen}</div>
      </div>
    </div>
    <div className="pt-5 pt-lg-0 col-12 col-lg-6 col-xl-4">
      <div className="widget-square" id="abgeschlossene">
        <span className="square-header">Abgeschlossene Fälle</span>
        <div className="square-content">{numbers.abgeschlossen}</div>
      </div>
    </div>
    <div className="pt-5 pt-xl-0 col-12 col-lg-6 col-xl-4">
      <div className="widget-square" id="insgesamt">
        <span className="square-header">Fälle Insgesamt</span>
        <div className="square-content">{numbers.insgesamt}</div>
      </div>
    </div>
  </div>
);

export default StatisticsWidget;
