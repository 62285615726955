import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';
import ImageThumbnail from './ImageThumbnail';

const TreatmentFinishedPictures = (): JSX.Element | undefined => {
  const { patient } = usePatientInfoStore();

  if (!patient) return undefined;

  return (
    <div className="row my-3">
      <div className="patienten-info col-12">
        <h4>Abschluss Bilder:</h4>
        <div className="row">
          {Object.keys(patient.treatmentFinishedPictures).map((key) => (
            <ImageThumbnail
              key={key}
              imageKey={key}
              image={patient.bilder[key as keyof typeof patient.bilder]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TreatmentFinishedPictures;
