import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';

const BehandlungsplanButton = (): JSX.Element | undefined => {
  const { patient, updateShowAngebotModal } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  // Render only if we have uploaded the STL and given the Auftragsfreigabe
  if (
    patient.meta.stlUploaded &&
    patient.meta.auftragsFreigabeAnimation &&
    !patient.meta.recievedOffer
  ) {
    return (
      <button
        type="button"
        onClick={(): void => {
          updateShowAngebotModal(true);
        }}
        className="btn btn-primary mx-2 my-2"
      >
        Behandlungsplan erstellen
      </button>
    );
  }
  return undefined;
};

export default BehandlungsplanButton;
