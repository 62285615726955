import { create } from 'zustand';
import Cookie, { CookieSetOptions } from 'universal-cookie';

type State = {
  jwtContent: {
    userName: string,
    userId: string,
    userRole: string,
  },
  jwt: string,
  isAuthenticated: boolean,
  showLoadingModal: boolean,
  progressBar: {
    show: boolean,
    progressPercent: number,
    title: string,
  },
};

type Action = {
  updateJwtContent: (content: State['jwtContent']) => void,
  updateJwt: (jwt: State['jwt']) => void,
  updateShowLoadingModal: (val: State['showLoadingModal']) => void,
  updateIsAuthenticated: (val: State['isAuthenticated']) => void,
  updateProgressBar: (val: State['progressBar']) => void,
  resetLoadingModal: () => void,
  logout: () => void,
};

const useAuthStore = create<State & Action>((set) => ({
  jwtContent: {
    userName: '',
    userId: '',
    userRole: '',
  },
  jwt: '',
  isAuthenticated: false,
  showLoadingModal: false,
  progressBar: {
    show: false,
    progressPercent: 0,
    title: '',
  },
  updateJwtContent: (jwtContent): void => set(() => ({ jwtContent })),
  updateJwt: (jwt): void => set(() => ({ jwt })),
  updateShowLoadingModal: (val): void => set(() => ({ showLoadingModal: val })),
  updateIsAuthenticated: (val): void => set(() => ({ isAuthenticated: val })),
  updateProgressBar: (val): void => set(() => ({ progressBar: val })),
  resetLoadingModal: (): void => {
    set(() => ({
      progressBar: {
        show: false,
        progressPercent: 0,
        title: '',
      },
      showLoadingModal: false,
    }));
  },
  logout: (): void => {
    const cookie = new Cookie();
    const cookieConfig: CookieSetOptions = {
      path: '/',
      secure: true,
      sameSite: 'strict',
    };

    if (process.env.NODE_ENV === 'production') {
      cookie.remove('smile-token', {
        ...cookieConfig,
        domain: 'champions-smile.de',
      });
    } else {
      cookie.remove('smile-token', cookieConfig);
    }
    set(() => ({ isAuthenticated: false }));
  },
}));

export default useAuthStore;
