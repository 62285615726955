import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';

const AbschlussBilderButton = (): JSX.Element | undefined => {
  const { patient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  if (
    patient.treatment.treatmentFinished &&
    patient.treatment.treatmentStatus === 'Abgeschlossen' &&
    !patient.meta.hasAllTreatmentFinishedPictures
  ) {
    return (
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={(): void => {}}
      >
        Abschlussbilder Einreichen
      </button>
    );
  }
  return undefined;
};

export default AbschlussBilderButton;
