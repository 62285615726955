import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import parse from 'html-react-parser';
import './NachrichtenShow.css';
import { Patient } from '@customTypes/Patient';
import useAuthStore from '@/stores/authStore';
import { Link, useParams } from 'react-router-dom';
import fetchPatient from '@/api/fetchPatient';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Sidebar from '@/components/sidebar/Sidebar';
import { toast } from 'react-toastify';
import postMessageRead from './functions/postMessageRead';
import { prettifyDate } from '../../../useful_functions';
import postMessage from './functions/postMessage';

type RouteProps = {
  id: string,
};

const NachrichtenShow = (): JSX.Element => {
  const { id } = useParams<RouteProps>();
  const { jwt, jwtContent, updateShowLoadingModal } = useAuthStore();
  const [patient, setPatient] = useState<Patient>();
  const [newMessage, setNewMessage] = useState<string>('');

  useEffect(() => {
    // if patient is not loaded or patient id is not the same as the id in the url
    if (!patient || patient.patientenNummer !== id) {
      updateShowLoadingModal(true);
      // fetch patient and hide loading modal
      fetchPatient(id, jwt)
        .then((res) => {
          setPatient(res);
          updateShowLoadingModal(false);
          postMessageRead(id, jwt);
        })
        .catch(() => {
          updateShowLoadingModal(false);
        });
    }
  }, [id]);

  const handleSubmitMessage = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      await postMessage(id, newMessage, jwt);
      const updatedPatient = await fetchPatient(id, jwt);
      setPatient(updatedPatient);
      setNewMessage('');
      updateShowLoadingModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Nachricht konnte nicht gesendet werden');
    }
  };
  if (!patient) return <div>Loading...</div>;
  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="nachrichten" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div className="nachrichten-show">
                <h2 className="header">
                  Chat - Patient {patient.patientenNummer}
                </h2>
                <div className="chat-container">
                  <div id="chat-history">
                    {patient.conversation
                      .slice()
                      .reverse()
                      .map((message) => (
                        <div
                          className={`chatbox ${
                            jwtContent.userName === message.sender
                              ? 'chatbox-right'
                              : 'chatbox-left'
                          }`}
                        >
                          <p>{parse(message.message)}</p>
                          <div className="chatbox-userstamp">
                            {message.sender}
                          </div>
                          <div className="chatbox-timestamp">
                            {prettifyDate(message.timestamp)}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div id="chat-new-message">
                    <div className="message-container row m-0">
                      <div className="col-11 p-0">
                        <TextareaAutosize
                          id="message-input"
                          value={newMessage}
                          onChange={(e): void => {
                            setNewMessage(e.target.value);
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        className="col-1 p-0 d-flex align-items-center justify-content-center send-icon"
                        onClick={handleSubmitMessage}
                      >
                        <div>Senden</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="m-3">
                  <Link className="btn btn-primary" to="/nachrichten">
                    Zurück
                  </Link>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default NachrichtenShow;
