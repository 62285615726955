import React from 'react';

import usePatientInfoStore from '@/stores/patientInfoStore';
import { apiHost } from '@/env_vars';
import { prettifyFileSize } from '@/useful_functions';

const PatientAttachments = (): JSX.Element => {
  const { patient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return <div />;

  return (
    <div>
      <h4>Anhänge</h4>
      {patient.attachments.map((attachment) => (
        <div className="mb-1" key={attachment.filename}>
          <a
            className="text-primary"
            href={`${apiHost}/uploads/${patient._id}/${attachment.filename}`}
            target="_blank"
            rel="noreferrer"
          >
            <b>{attachment.filename}</b> -{' '}
            {prettifyFileSize(parseInt(attachment.size, 10))}
          </a>
          <br />
        </div>
      ))}
    </div>
  );
};

export default PatientAttachments;
