// Submit Function checks for values in Step 4

import { apiHost } from '../../../env_vars';
import { FormType } from './types/FormTypes';

// and then Submits it to the Server via Fetch
const postSubmit = async (formValues: FormType): Promise<string> => {
  const userObject = {
    ...formValues,
    praxScanner: formValues.praxScanner === '3dScan',
  };
  const url = `${apiHost}/user`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(userObject),
  };
  try {
    const response = await fetch(url, options);
    const parsedResponse = await response.json();
    if (response.status === 200) {
      return '';
    }
    let errorMessage = '';
    if (response.status === 400) {
      parsedResponse.errors.forEach((err: { message: string }) => {
        errorMessage += `${err.message}, `;
      });
    } else {
      errorMessage = 'Ein unbekannter Fehler ist aufgetreten';
    }
    return errorMessage;
  } catch (e) {
    return 'Ein unbekannter Fehler ist aufgetreten';
  }
};

export default postSubmit;
