import { apiHost } from '@/env_vars';
import useAuthStore from '@/stores/authStore';
import axios, { AxiosRequestConfig } from 'axios';

const postAttachment = async (
  jwt: string,
  patientId: string,
  file: File,
): Promise<void> => {
  useAuthStore.getState().updateProgressBar({
    show: true,
    progressPercent: 0,
    title: 'Anhang Hochladen',
  });
  const url = `${apiHost}/patient/${patientId}/attachment`;
  const formData = new FormData();
  formData.append('_id', patientId);
  formData.append('file', file);
  const options: AxiosRequestConfig = {
    onUploadProgress: (data): void => {
      useAuthStore.getState().updateProgressBar({
        show: true,
        progressPercent: data.total
          ? Math.round((100 * data.loaded) / data.total)
          : 0,
        title: 'Anhang Hochladen',
      });
    },
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.post(url, formData, options);
};

export default postAttachment;
