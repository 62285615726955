import { User } from '@customTypes/User';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  user: User,
};

const UserPatients = ({ user }: Props): JSX.Element => {
  return (
    <div className="user-patients">
      <h4>Patienten</h4>
      <div>
        <table className="w-100">
          <thead>
            <tr style={{ borderBottom: '1px solid #dddddd' }}>
              <th className="left">Patientennummer</th>
              <th className="right">Aktion</th>
            </tr>
          </thead>
          <tbody>
            {user.patients.length > 0 ? (
              user.patients.map((patient) => (
                <tr>
                  <td>{patient.patientenNummer}</td>
                  <td>
                    <Link
                      to={`/patienten/show/${patient._id}`}
                      className="btn btn-md btn-primary"
                    >
                      Öffnen
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <span>Keine Patienten</span>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserPatients;
