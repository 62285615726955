import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import submitPassword from './functions/submitPassword';
import validateNewPassword from './functions/validateNewPassword';
import './PasswordChange.css';

type Props = {
  changeContainer: (container: string) => void,
};

const PasswordChange = ({ changeContainer }: Props): JSX.Element => {
  const { jwt, jwtContent, logout, updateShowLoadingModal } = useAuthStore();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    try {
      const errors = validateNewPassword(
        currentPassword,
        newPassword,
        newPasswordRepeat,
      );
      setValidationErrors(errors);
      if (errors.length === 0) {
        updateShowLoadingModal(true);
        await submitPassword(
          jwtContent.userId,
          currentPassword,
          newPassword,
          jwt,
        );
        updateShowLoadingModal(false);
        setShowLogoutModal(true);
      }
    } catch (error) {
      toast.error('Passwort konnte nicht geändert werden');
      updateShowLoadingModal(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="einstellungen-box col-4">
          <h3 className="header">Passwort Ändern</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-5 einstellungen-box my-3">
          <Form.Group>
            <Form.Label>Ihr Aktuelles Passwort</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ihr Aktuelles Passwort"
              value={currentPassword}
              onChange={(e): void => {
                setCurrentPassword(e.target.value);
              }}
              className={
                validationErrors.includes('currentPassword') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Das Passwort ist ungültig
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Ihr Neues Passwort</Form.Label>
            <Form.Control
              type="password"
              placeholder="Neues Passwort"
              value={newPassword}
              className={
                validationErrors.includes('newPassword') ? 'is-invalid' : ''
              }
              onChange={(e): void => {
                setNewPassword(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben sie Ihr neues Passwort ein mit mindestens 8
              Buchstaben!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Passwort Wiederholen</Form.Label>
            <Form.Control
              type="password"
              placeholder="Passwort Wiederholen"
              value={newPasswordRepeat}
              className={
                validationErrors.includes('newPasswordRepeat')
                  ? 'is-invalid'
                  : ''
              }
              onChange={(e): void => {
                setNewPasswordRepeat(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Die Passwörter müssen übereinstimmen
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-center mt-3">
            <button
              type="button"
              className="btn btn-primary mx-4"
              onClick={(): void => changeContainer('main')}
            >
              Zurück
            </button>
            <button
              type="button"
              className="btn btn-primary mx-4"
              onClick={handleSubmit}
            >
              Senden
            </button>
          </div>
        </div>
      </div>
      <Modal show={showLogoutModal}>
        <Modal.Header>
          <Modal.Title>Passwort Ändern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Passwort erfolgreich geändert, Sie müssen sich nun neu Anmelden</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={logout}>
            Neu Anmelden
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PasswordChange;
