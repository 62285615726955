import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FormType } from './types/FormTypes';

type RegisterStep4Props = {
  formValues: FormType,
  validation: string[],
  submitErrorMessage: string,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

const RegisterStep4 = ({
  formValues,
  validation,
  handleChange,
  submitErrorMessage,
}: RegisterStep4Props): JSX.Element => (
  <div className="text-left my-2">
    <p>
      <i>Benutzer Daten:</i>
    </p>
    {submitErrorMessage && (
      <div className="submit-error">
        <h4>Fehler:</h4>
        <p>{submitErrorMessage}</p>
      </div>
    )}
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridBenutzername">
        <Form.Label>Benutzername</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ihr Benutzername"
          name="benutzername"
          value={formValues.benutzername}
          onChange={handleChange}
          className={validation.includes('benutzername') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Ihr Benutzername ist ungültig oder ist schon bereits belegt
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridPasswort">
        <Form.Label>Passwort</Form.Label>
        <Form.Control
          type="password"
          placeholder="Ihr Passwort"
          name="password"
          value={formValues.password}
          onChange={handleChange}
          className={validation.includes('password') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Das Passwort muss mindestens 8 Zeichen lang sein und muss enthalten: 1
          Grossbuchstabe, 1 Kleinbuchstabe und eine Zahl
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridRepPasswort">
        <Form.Label>Passwort Wiederholen</Form.Label>
        <Form.Control
          type="password"
          placeholder="Ihr Passwort Wiederholen"
          name="repPassword"
          value={formValues.repPassword}
          onChange={handleChange}
          className={validation.includes('repPassword') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Ihre Passwörter müssen übereinstimmen
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group className="pl-3 mt-2">
        <Form.Check
          required
          type="switch"
          id="license-switch"
          label="Ich akzeptiere die Datenschutzpolitik und die AGBs"
          feedback="Sie müssen Akzeptieren."
          name="licenseAgreement"
          onChange={handleChange}
          className={validation.includes('password') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Sie müssen die AGBs akzeptieren
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
  </div>
);

export default RegisterStep4;
