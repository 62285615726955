import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Sidebar from '@/components/sidebar/Sidebar';
import useAuthStore from '@/stores/authStore';
import { isAxiosError } from 'axios';
import postCode from './functions/postCode';
import './VerifCodesNew.css';
import validateCode from './functions/validateCode';

const VerifCodesNew = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const [email, setEmail] = useState<string>('');
  const [notiz, setNotiz] = useState<string>('');
  const [kundenNummer, setKundenNummer] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [redirect, setRedirect] = useState<boolean>(false);

  const handleSubmitCode = async (): Promise<void> => {
    try {
      const errors = validateCode(email, kundenNummer);
      setValidationErrors(errors);
      if (errors.length === 0) {
        updateShowLoadingModal(true);
        await postCode({ email, kundenNummer, notiz }, jwt);
        updateShowLoadingModal(false);
        toast.success('Code wurde hinzugefügt');
        setRedirect(true);
      }
    } catch (error) {
      updateShowLoadingModal(false);
      if (isAxiosError(error) && error.response?.data) {
        toast.error(`Fehler: ${error.response?.data?.message}`);
      } else {
        toast.error('Code konnte nicht hinzugefügt werden');
      }
    }
  };

  if (redirect) {
    return <Redirect to="/verifcodes" />;
  }

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="verifCodes" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div>
                <div className="row">
                  <div className="verifcode-box col-lg-6">
                    <h2 className="header">Verifizierungscode Hinzufügen</h2>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="verifcode-box col-lg-6">
                    <Form>
                      <Form.Group>
                        <Form.Label>Email des Anwenders</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="max@mustermann.com"
                          value={email}
                          onChange={(e): void => {
                            setEmail(e.target.value);
                          }}
                          className={
                            validationErrors.includes('email')
                              ? 'is-invalid'
                              : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte geben Sie eine gültige E-Mail Adresse ein
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Kundennummer</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="XX-XXXX"
                          value={kundenNummer}
                          onChange={(e): void => {
                            setKundenNummer(e.target.value);
                          }}
                          className={
                            validationErrors.includes('kundenNummer')
                              ? 'is-invalid'
                              : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte geben Sie eine gültige Kundennummer ein !
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Notiz</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="z.b. Für Dr. Mustermann"
                          value={notiz}
                          onChange={(e): void => {
                            setNotiz(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Form>
                    <div className="mt-3">
                      <Link to="/verifcodes" className="btn btn-primary mx-2">
                        Zurück
                      </Link>
                      <button
                        type="button"
                        className="btn btn-primary mx-2"
                        onClick={handleSubmitCode}
                      >
                        Hinzufügen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default VerifCodesNew;
