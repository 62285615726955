import { apiHost } from '@/env_vars';
import axios, { AxiosRequestConfig } from 'axios';

const deleteAttachment = async (
  jwt: string,
  patientId: string,
  fileIndex: string,
): Promise<void> => {
  const url = `${apiHost}/patient/${patientId}/attachment/${fileIndex}`;

  const options: AxiosRequestConfig = {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.delete(url, options);
};

export default deleteAttachment;
