import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useAuthStore from '@/stores/authStore';
import { User } from '@customTypes/User';
import { toast } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Sidebar from '@/components/sidebar/Sidebar';
import UserDetails from './Components/UserDetails';
import UserPatients from './Components/UserPatients';
import UserEdit from './Components/UserEdit';
import './UserInfo.css';
import submitPassword from './functions/submitPassword';
import fetchUserById from './functions/fetchUserById';

type RouteProps = {
  id: string,
};

const UserInfo = (): JSX.Element => {
  const { id } = useParams<RouteProps>();
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const [user, setUser] = useState<User>();
  const [editMode, setEditMode] = useState(false);
  const [passwordModal, setPasswordModal] = useState({
    show: false,
    password: '',
    passwordRep: '',
    validation: {
      password: false,
      passwordRep: false,
    },
  });

  const updateUser = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      const fetchedUser = await fetchUserById(jwt, id);
      setUser(fetchedUser);
      updateShowLoadingModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
    }
  };

  const closePasswordModal = (): void => {
    setPasswordModal({
      show: false,
      password: '',
      passwordRep: '',
      validation: {
        password: false,
        passwordRep: false,
      },
    });
  };

  const handleSubmitPassword = async (): Promise<void> => {
    const validation = {
      password: false,
      passwordRep: false,
    };
    // Password Check
    if (passwordModal.password.length < 8) {
      validation.password = true;
      setPasswordModal({ ...passwordModal, validation });
    }
    if (
      !passwordModal.password.match(
        '^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$',
      )
    ) {
      validation.password = true;
      setPasswordModal({ ...passwordModal, validation });
    }
    if (passwordModal.password !== passwordModal.passwordRep) {
      validation.passwordRep = true;
      setPasswordModal({ ...passwordModal, validation });
    }
    setPasswordModal({
      ...passwordModal,
      validation,
    });
    if (validation.password || validation.passwordRep) return;
    try {
      updateShowLoadingModal(true);
      await submitPassword(jwt, id, passwordModal.password);
      toast.success('Passwort erfolgreich geändert');
      updateShowLoadingModal(false);
      closePasswordModal();
    } catch (error) {
      updateShowLoadingModal(false);
      closePasswordModal();
      toast.error('Fehler beim Speichern des Passworts');
    }
  };

  const showPasswordModal = (show: boolean): void => {
    setPasswordModal({
      ...passwordModal,
      show,
    });
  };

  useEffect(() => {
    updateUser();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="benutzerverwaltung" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div>
                <div className="row my-3">
                  <div className="user-info col-6">
                    {editMode ? (
                      <UserEdit
                        user={user}
                        updateUser={updateUser}
                        setEditMode={setEditMode}
                      />
                    ) : (
                      <UserDetails
                        user={user}
                        showPasswordModal={showPasswordModal}
                        setEditMode={setEditMode}
                      />
                    )}
                  </div>
                  <div className="col-1" />
                  <div className="user-info col-5">
                    <UserPatients user={user} />
                  </div>
                </div>
                <div className="row my-3 justify-content-center">
                  <div className="patienten-info col-6">
                    <div className="text-center d-flex justify-content-center">
                      <Link
                        className="btn btn-primary mx-5"
                        to="/benutzerverwaltung"
                      >
                        Zurück
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      <Modal show={passwordModal.show} backdrop="static" className="" centered>
        <Modal.Header>
          <Modal.Title>Passwort Ändern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Ihr Neues Passwort</Form.Label>
            <Form.Control
              type="password"
              placeholder="Neues Passwort"
              value={passwordModal.password}
              className={passwordModal.validation.password ? 'is-invalid' : ''}
              onChange={(e): void => {
                setPasswordModal({
                  ...passwordModal,
                  password: e.target.value,
                });
              }}
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben sie das neue Passwort ein !
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Passwort Wiederholen</Form.Label>
            <Form.Control
              type="password"
              placeholder="Passwort Wiederholen"
              value={passwordModal.passwordRep}
              className={
                passwordModal.validation.passwordRep ? 'is-invalid' : ''
              }
              onChange={(e): void => {
                setPasswordModal({
                  ...passwordModal,
                  passwordRep: e.target.value,
                });
              }}
            />
            <Form.Control.Feedback type="invalid">
              Die Passwörter müssen übereinstimmen
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(): void => showPasswordModal(false)}
          >
            Abbrechen
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmitPassword}
          >
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserInfo;
