import useNewPatientStore from '@/stores/newPatientStore';

const validateStep1 = (): string[] => {
  const validationErrors = [];
  const minDate = new Date('1940-01-01');
  const maxDate = new Date(Date.now());
  const { intPatNr, geburtsdatum, geschlecht, overjet, overbite } =
    useNewPatientStore.getState();
  if (geburtsdatum === '') {
    validationErrors.push('geburtsdatum');
  } else {
    try {
      const birthDate = new Date(geburtsdatum);
      if (birthDate < minDate || birthDate > maxDate) {
        validationErrors.push('geburtsdatum');
      }
    } catch (error) {
      validationErrors.push('geburtsdatum');
    }
  }

  if (geschlecht !== 'Männlich' && geschlecht !== 'Weiblich') {
    validationErrors.push('geschlecht');
  }

  if (intPatNr === '') {
    validationErrors.push('intPatNr');
  }

  if (overjet === '') {
    validationErrors.push('overjet');
  }

  if (overbite === '') {
    validationErrors.push('overbite');
  }

  return validationErrors;
};

export default validateStep1;
