import { apiHost } from '../../../../env_vars';

const postMessageRead = async (
  patientId: string,
  jwt: string,
): Promise<void> => {
  const url = `${apiHost}/patient/${patientId}/messages_read`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await fetch(url, options);
};

export default postMessageRead;
