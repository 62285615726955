import { apiHost } from '@/env_vars';
import { Patient } from '@customTypes/Patient';

/*
    fetchPatient()
    This function is used to fetch a patient from the backend
*/

const fetchPatient = async (
  patientId: string,
  jwt: string,
): Promise<Patient> => {
  const url = `${apiHost}/patient/${patientId}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error('Could not fetch Patient');
  }
  const parsedResponse = await response.json();
  return parsedResponse;
};

export default fetchPatient;
