import Cookie from 'universal-cookie';
import { apiHost } from '../../../env_vars';

const postLogin = async (
  benutzername: string,
  password: string,
): Promise<string> => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      benutzername,
      password,
    }),
  };
  const url = `${apiHost}/login`;
  try {
    const response = await fetch(url, options);
    const parsedResponse = await response.json();
    if (response.status === 200) {
      const cookie = new Cookie();
      if (process.env.NODE_ENV === 'production') {
        cookie.set('smile-token', parsedResponse.token, {
          path: '/',
          secure: true,
          domain: 'champions-smile.de',
          sameSite: 'strict',
        });
      } else {
        cookie.set('smile-token', parsedResponse.token, {
          path: '/',
          secure: true,
          sameSite: 'strict',
        });
      }
      return '';
    }
    return parsedResponse.message;
  } catch (error) {
    return 'Es gab ein Fehler beim herstellen der Verbindung zum Champions Smile Server';
  }
};

export default postLogin;
