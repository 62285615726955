import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FormType } from './types/FormTypes';

type RegisterStep2Props = {
  formValues: FormType,
  validation: string[],
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

const RegisterStep2 = ({
  formValues,
  validation,
  handleChange,
}: RegisterStep2Props): JSX.Element => (
  <div className="text-left my-2">
    <p>
      <i>Persönliche Angaben:</i>
    </p>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridName">
        <Form.Label>Vorname</Form.Label>
        <Form.Control
          type="text"
          placeholder="Vorname"
          name="vorname"
          value={formValues.vorname}
          onChange={handleChange}
          className={validation.includes('vorname') ? 'is-invalid' : ''}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben sie einen Namen ein
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} controlId="formGridSurname">
        <Form.Label>Nachname</Form.Label>
        <Form.Control
          type="test"
          placeholder="Nachname"
          name="nachname"
          value={formValues.nachname}
          onChange={handleChange}
          className={validation.includes('nachname') ? 'is-invalid' : ''}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben sie einen Nachnamen ein
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
          className={validation.includes('email') ? 'is-invalid' : ''}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben sie eine gültige E-Mail adresse ein
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Telefon</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Bitte eine Telefonnummer für Rückfragen angeben"
          name="telefon"
          value={formValues.telefon}
          onChange={handleChange}
          className={validation.includes('telefon') ? 'is-invalid' : ''}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben sie eine gültige Telefonnummer ein
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
  </div>
);

export default RegisterStep2;
