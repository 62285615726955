import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';
import useAuthStore from '@/stores/authStore';
import AcceptRejectCaseButton from './ActionButtons/Admin/AcceptRejectCaseButton';
import AuftragsfreigabeButton from './ActionButtons/Admin/AuftragsfreigabeButton';
import BehandlungAbschliessenButton from './ActionButtons/Admin/BehandlungAbschliessenButton';
import BehandlungsplanButton from './ActionButtons/Admin/BehandlungsplanButton';
import DownloadZipButton from './ActionButtons/Admin/DownloadZipButton';
import SchieneVersendetButton from './ActionButtons/Admin/SchieneVersendetButton';
import UploadStlButton from './ActionButtons/Admin/UploadStlButton';
import AbschlussBilderButton from './ActionButtons/User/AbschlussBilderButton';
import DentworryInstructionsButton from './ActionButtons/User/DentworryInstructionsButton';
import UploadStlButtonViewer from './ActionButtons/User/UploadStlButton';
import UploadStlButtonDentworry from './ActionButtons/Dentworry/UploadStlButtonDentworry';
import UploadAttachmentButton from './ActionButtons/Admin/UploadAttachmentButton';

const Actions = (): JSX.Element => {
  const { patient } = usePatientInfoStore();
  const { jwtContent } = useAuthStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return <div />;

  return (
    <div className="p-4">
      {jwtContent.userRole === 'admin' && !patient.meta.caseClosed ? (
        <>
          <AcceptRejectCaseButton />
          <AuftragsfreigabeButton />
          <BehandlungAbschliessenButton />
          <BehandlungsplanButton />
          <DownloadZipButton />
          <SchieneVersendetButton />
          <UploadStlButton />
          <UploadAttachmentButton />
        </>
      ) : undefined}
      {jwtContent.userRole === 'viewer' && !patient.meta.caseClosed ? (
        <>
          <AbschlussBilderButton />
          <DentworryInstructionsButton />
          <UploadStlButtonViewer />
        </>
      ) : undefined}
      {jwtContent.userRole === 'dentworry' && <UploadStlButtonDentworry />}
    </div>
  );
};

export default Actions;
