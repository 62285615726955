const validateRegister = (benutzername: string, password: string): string[] => {
  const errors = [];

  if (benutzername.length < 4) {
    errors.push('benutzername');
  }

  if (password.length < 8) {
    errors.push('password');
  }

  return errors;
};

export default validateRegister;
