import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';

const UploadAttachmentButton = (): JSX.Element | undefined => {
  const {
    patient,
    updateShowUploadAttachmentModal,
    updateShowDeleteAttachmentModal,
  } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  // Render only if we are admin and we havent uploaded the STL yet

  return (
    <div>
      <span className="d-block mb-2 bold">Anhänge</span>
      <button
        type="button"
        className="btn btn-secondary mx-2"
        onClick={(): void => {
          updateShowUploadAttachmentModal(true);
        }}
      >
        Anhang Hochladen
      </button>
      {patient.attachments && patient.attachments.length > 0 && (
        <button
          type="button"
          className="btn btn-danger mx-2"
          onClick={(): void => {
            updateShowDeleteAttachmentModal(true);
          }}
        >
          Anhang Löschen
        </button>
      )}
    </div>
  );
};

export default UploadAttachmentButton;
