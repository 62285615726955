import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';

const UploadStlButtonViewer = (): JSX.Element | undefined => {
  const { patient, updateShowUploadSTLModal } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  if (!patient.meta.stlUploaded && patient.meta.homeScan) {
    return (
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={(): void => {
          updateShowUploadSTLModal(true);
        }}
      >
        STL Datei Hochladen
      </button>
    );
  }
  return undefined;
};

export default UploadStlButtonViewer;
