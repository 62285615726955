import { FormType } from '../types/FormTypes';
import { apiHost } from '../../../../env_vars';

const validateStep1 = async (form: FormType): Promise<string[]> => {
  if (form.verifCode === '' || form.verifCode.length !== 10) {
    return ['verifCode'];
  }
  const token = form.verifCode;
  const url = `${apiHost}/registerKey/${token}?usingToken=true`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  try {
    const response = await fetch(url, options);
    if (response.status === 200) {
      return [];
    }
    return ['verifCode'];
  } catch (e) {
    return ['verifCode'];
  }
};

export default validateStep1;
