import usePatientInfoStore from '@/stores/patientInfoStore';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import deleteAttachment from './functions/deleteAttachment';

const DeleteAttachmentModal = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const {
    patient,
    reloadPatient,
    showDeleteAttachmentModal,
    updateShowDeleteAttachmentModal,
  } = usePatientInfoStore();
  const [deleteIndex, setDeleteIndex] = useState<string>();
  const [hintText, setHintText] = useState<string>('');

  const closeForm = (): void => {
    updateShowDeleteAttachmentModal(false);
    setDeleteIndex(undefined);
  };

  const submitFiles = async (): Promise<void> => {
    try {
      if (!deleteIndex) {
        setHintText('Bitte eine Datei auswählen');
        return;
      }
      setHintText('');
      // If there is no patient, return
      if (!patient) return;
      updateShowLoadingModal(true);
      await deleteAttachment(jwt, patient._id, deleteIndex);
      await reloadPatient(jwt, patient._id);
      closeForm();
      toast.success('Anhang erfolgreich gelöscht');
      updateShowLoadingModal(false);
    } catch (e) {
      updateShowLoadingModal(false);
      closeForm();
      toast.error('Fehler beim Löschen des Anhangs');
    }
  };

  return (
    <Modal
      show={showDeleteAttachmentModal}
      backdrop="static"
      className=""
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h3>Anhang Löschen </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mx-4 my-2">
          <div>
            <Form.Group>
              <Form.Label>Bitte den zu löschenden Anhang auswählen</Form.Label>
              <Form.Control
                as="select"
                name="geschlecht"
                onChange={(e): void => setDeleteIndex(e.target.value)}
                value={deleteIndex}
              >
                <option disabled selected value="">
                  Bitte auswählen
                </option>
                {patient?.attachments.map((attachment, index) => (
                  <option value={index}>{attachment.filename}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        {hintText !== '' && (
          <div
            className="bg-hint-alert text-center mx-4 px-4 py-2 my-3"
            style={{ maxWidth: '500px' }}
          >
            <h5>{hintText}</h5>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" type="button" onClick={closeForm}>
          Zurück
        </button>
        <button type="button" className="btn btn-primary" onClick={submitFiles}>
          Hochladen
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAttachmentModal;
