import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import useNewPatientStore from '@/stores/newPatientStore';
import { Link } from 'react-router-dom';
import validateStep1 from './functions/validateGeneralInformation';

const GeneralInformation = (): JSX.Element => {
  const {
    updateCurrentStep,
    intPatNr,
    updateIntPatNr,
    geburtsdatum,
    updateGeburtsdatum,
    geschlecht,
    updateGeschlecht,
    overbite,
    updateOverbite,
    overjet,
    updateOverjet,
  } = useNewPatientStore();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleSubmit = (): void => {
    const errors = validateStep1();
    setValidationErrors(errors);
    if (errors.length === 0) {
      updateCurrentStep(2);
    }
  };
  return (
    <div className="patienten-step step1">
      <div>
        <h5>Details:</h5>
      </div>
      <Form>
        <Form.Group controlId="newPatient.vorname">
          <Form.Label>Ihre Interne Patientennummer</Form.Label>
          <Form.Control
            type="text"
            placeholder="XXXX-XX"
            name="intPatNr"
            onChange={(e): void => updateIntPatNr(e.target.value)}
            value={intPatNr}
            className={
              validationErrors.includes('intPatNr') ? 'is-invalid' : ''
            }
          />
          <Form.Control.Feedback type="invalid">
            Bitte geben Sie eine gültige Patientenummer ein
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="newPatient.nachname">
          <Form.Label>Geburtsdatum</Form.Label>
          <Form.Control
            type="date"
            placeholder="dd-mm-yyyy"
            name="geburtsdatum"
            onChange={(e): void => updateGeburtsdatum(e.target.value)}
            value={geburtsdatum}
            className={
              validationErrors.includes('geburtsdatum') ? 'is-invalid' : ''
            }
          />
          <Form.Control.Feedback type="invalid">
            Bitte geben Sie ein gültiges Geburtsdatum ein
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="newPatient.nachname">
          <Form.Label>Geschlecht</Form.Label>
          <Form.Control
            as="select"
            name="geschlecht"
            onChange={(e): void => updateGeschlecht(e.target.value)}
            value={geschlecht}
            className={
              validationErrors.includes('geschlecht') ? 'is-invalid' : ''
            }
          >
            <option>Männlich</option>
            <option>Weiblich</option>
          </Form.Control>
        </Form.Group>
        <Row className="pb-3">
          <Col>
            <Form.Label>Overjet in mm</Form.Label>
            <Form.Control
              type="number"
              placeholder="Bitte in Millimeter angeben"
              name="overjet"
              onChange={(e): void => updateOverjet(e.target.value)}
              value={overjet}
              className={
                validationErrors.includes('overjet') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie einen gültigen Wert ein
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label>Overbite in mm</Form.Label>
            <Form.Control
              type="number"
              placeholder="Bitte in Millimeter angeben"
              name="overbite"
              onChange={(e): void => updateOverbite(e.target.value)}
              value={overbite}
              className={
                validationErrors.includes('overbite') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie einen gültigen Wert ein
            </Form.Control.Feedback>
          </Col>
        </Row>
        <div className="d-flex justify-content-around">
          <Link to="/patienten" className="btn btn-secondary">
            Zurück
          </Link>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(): void => handleSubmit()}
          >
            Weiter
          </button>
        </div>
      </Form>
    </div>
  );
};

export default GeneralInformation;
