import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';

const BehandlungAbschliessenButton = (): JSX.Element | undefined => {
  const { patient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  if (patient.meta.auftragsFreigabe && !patient.treatment.treatmentFinished) {
    return (
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={(): void => {}}
      >
        Behandlung Abgeschlossen
      </button>
    );
  }
  return undefined;
};

export default BehandlungAbschliessenButton;
