import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import validateLogin from './validateLogin';
import LoginSuccess from './LoginSuccess';
import postLogin from './postLogin';

type Props = {
  changeContainer: (val: string) => void,
};

const LoginContainer = ({ changeContainer }: Props): JSX.Element => {
  const [benutzername, setBenutzername] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    setValidationErrors([]);
    const validationResult = validateLogin(benutzername, password);
    // If weve got an Validation error we Return and Show errors;
    if (validationResult.length > 0) {
      setValidationErrors(validationResult);
    }
    const result = await postLogin(benutzername, password);
    if (result !== '') {
      setErrorMessage(result);
    } else {
      setLoginSuccess(true);
    }
  };

  if (loginSuccess) {
    return <LoginSuccess />;
  }
  return (
    <div className="login-form-container">
      <div>
        <h1 className="form-header">Einloggen</h1>
      </div>
      <hr className="w-75" />
      <Form className="login-form">
        {errorMessage !== '' && (
          <div className="submit-error m-3">
            <h4>Fehler:</h4>
            <p>{errorMessage}</p>
          </div>
        )}
        <Form.Group
          as={Row}
          controlId="formHorizontalEmail"
          className="justify-content-center py-2"
        >
          <Form.Label column sm={9} xl={3}>
            Benutzername:
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Benutzername oder E-Mail"
              value={benutzername}
              onChange={(e): void => setBenutzername(e.target.value)}
              className={
                validationErrors.includes('benutzername') ? 'is-invalid' : ''
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben sie einen gültigen Benutzernamen ein
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          controlId="formHorizontalPassword"
          className="justify-content-center pb-3"
        >
          <Form.Label column sm={9} xl={3}>
            Password:
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e): void => setPassword(e.target.value)}
              className={
                validationErrors.includes('password') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben sie ein gültiges Passwort ein
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div className="d-flex justify-content-around">
          <button
            type="button"
            className="login-button"
            onClick={(): void => changeContainer('select')}
          >
            Zurück
          </button>
          <button type="button" onClick={handleSubmit} className="login-button">
            Log-In
          </button>
        </div>
      </Form>
    </div>
  );
};

export default LoginContainer;
