import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  show: boolean,
  setShow: (show: boolean) => void,
  animationsLink: string,
};

const AnimationsModal = ({
  show,
  setShow,
  animationsLink,
}: Props): JSX.Element => {
  const [hideAnimationsBanner, setHideAnimationsBanner] = useState(false);

  // fix for alternative host where we dont need the banner
  useEffect(() => {
    try {
      if (animationsLink !== '') {
        const { host } = new URL(animationsLink);
        if (host === 'doctor.3dortho-plan.com') {
          setHideAnimationsBanner(true);
        }
      }
    } catch (error) {
      // do nothing
    }
  }, [animationsLink]);

  return (
    <Modal
      show={show}
      size="xl"
      style={{ padding: '0.2rem auto' }}
      onHide={(): void => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Animation Ansehen</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ height: '85vh' }}>
        <iframe
          className="w-100 h-100"
          title="Animation"
          src={animationsLink}
        />
        {!hideAnimationsBanner && (
          <div className="w-100" id="smile-animation-logo-container">
            <div id="smile-animation-logo">
              <img src="/assets/logo.png" alt="Champions-Smile-Logo" />
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div>
          <button
            type="button"
            className="btn btn-secondary mx-3"
            onClick={(): void => setShow(false)}
          >
            Zurück
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AnimationsModal;
