import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import stillLoggedIn from './stillLoggedIn';
import LoadingModal from './components/ModalLoadingScreen/ModalLoadingScreen';
import useAuthStore from './stores/authStore';

type Props = {
  children: React.ReactNode,
};

const ProtectedRoute = ({ children }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    showLoadingModal,
    updateJwt,
    updateJwtContent,
    isAuthenticated,
    updateIsAuthenticated,
    progressBar,
  } = useAuthStore();

  useEffect(() => {
    stillLoggedIn()
      .then(({ jwt, jwtContent }) => {
        updateJwt(jwt);
        updateJwtContent({
          userId: jwtContent._id,
          userName: jwtContent.userName,
          userRole: jwtContent.userRole,
        });
        updateIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch((e) => {
        if (e === 'INACTIVITY')
          setErrorMessage('Sie wurden wegen inaktivität abgemeldet');
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (isAuthenticated) {
    return (
      <div>
        {children}
        <LoadingModal
          progressBar={progressBar.show}
          progressBarPercent={progressBar.progressPercent}
          progressBarText={progressBar.title}
          loading={showLoadingModal}
        />
        <ToastContainer />
      </div>
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          errorDialog: errorMessage !== '',
          errorMessage,
        },
      }}
    />
  );
};

export default ProtectedRoute;
