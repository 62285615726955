import React from 'react';

type Props = {
  changeContainer: (val: string) => void,
};

const SelectContainer = ({ changeContainer }: Props): JSX.Element => (
  <div className="select-prompt">
    <h1 className="form-header">Willkommen beim Anwender Portal</h1>
    <hr className="w-50 mx-auto" />
    <button
      type="button"
      className="select-button"
      onClick={(): void => changeContainer('login')}
    >
      Einloggen
    </button>
    <button
      type="button"
      className="select-button"
      onClick={(): void => changeContainer('register')}
    >
      Registrieren
    </button>
  </div>
);

export default SelectContainer;
