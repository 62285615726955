import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';
import { Form, Modal } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import postSchieneVersendet from '../functions/postSchieneVersendet';

const SchieneVersendetButton = (): JSX.Element | undefined => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [trackingnummer, setTrackingnummer] = React.useState<string>('');
  const [trackingnummerValidation, setTrackingnummerValidation] =
    React.useState<boolean>(false);

  const { jwt, updateShowLoadingModal } = useAuthStore();
  const { patient, reloadPatient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  const handleSubmit = async (): Promise<void> => {
    if (trackingnummer === '') {
      setTrackingnummerValidation(true);
      return;
    }
    try {
      updateShowLoadingModal(true);
      await postSchieneVersendet(jwt, patient._id, trackingnummer);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
      setShowModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
    }
  };

  if (!patient.meta.auftragsFreigabe && !patient.treatment.treatmentFinished) {
    return (
      <>
        {' '}
        <button
          type="button"
          className="btn btn-primary mx-2 my-2"
          onClick={(): void => setShowModal(true)}
        >
          Schiene Versendet
        </button>
        {/*
         * Schiene Versendet Modal
         */}
        <Modal
          centered
          show={showModal}
          onHide={(): void => setShowModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Schiene Versendet</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <p>
                Bitte geben sie die Tracking Nummer ein für die Schiene die sie
                versendet haben.
              </p>
              <Form.Group>
                <Form.Label>Tracking Nummer</Form.Label>
                <Form.Control
                  type="text"
                  name="trackingnummer"
                  onChange={(e): void => {
                    setTrackingnummer(e.target.value);
                  }}
                  className={trackingnummerValidation ? 'is-invalid' : ''}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben sie einen Trackingnummer ein !
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <div>
              <button
                type="button"
                className="btn btn-secondary mx-3"
                onClick={(): void => {
                  setShowModal(false);
                }}
              >
                Zurück
              </button>
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={handleSubmit}
              >
                Versendet
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  return undefined;
};

export default SchieneVersendetButton;
