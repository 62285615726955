export const prettifyDate = (date: string, onlyDate?: boolean): string => {
  const dateObject = new Date(date);
  if (onlyDate) {
    const parsedDate = `${
      dateObject.getDate() < 10
        ? `0${dateObject.getDate()}`
        : dateObject.getDate()
    }.${
      dateObject.getMonth() + 1 < 10
        ? `0${dateObject.getMonth() + 1}`
        : dateObject.getMonth() + 1
    }.${dateObject.getFullYear()}`;
    return parsedDate;
  }
  const parsedDate = `${
    dateObject.getDate() < 10
      ? `0${dateObject.getDate()}`
      : dateObject.getDate()
  }.${
    dateObject.getMonth() + 1 < 10
      ? `0${dateObject.getMonth() + 1}`
      : dateObject.getMonth() + 1
  }.${dateObject.getFullYear()} - ${
    dateObject.getHours() < 10
      ? `0${dateObject.getHours()}`
      : dateObject.getHours()
  }:${
    dateObject.getMinutes() < 10
      ? `0${dateObject.getMinutes()}`
      : dateObject.getMinutes()
  }`;
  return parsedDate;
};

export const prettifyFileSize = (size: number): string => {
  const mb = 1048576;
  const kb = 1024;
  // Bigger than 1 MB
  if (size > mb) {
    return `${Math.round((size / mb) * 100) / 100} MB`;
  }
  return `${Math.round((size / kb) * 100) / 100} KB`;
};
