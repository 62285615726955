import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginRegister from './pages/login/LoginRegisterContainer';
import Dashboard from './pages/dashboard/Dashboard';
import Einstellungen from './pages/einstellungen/Einstellungen';
import ProtectedRoute from './ProtectedRoute';
import PatientenInfo from './pages/patienten/PatientenId/PatientenInfo/PatientenInfo';
import PatientenList from './pages/patienten/PatientenList/PatientenList';
import PatientenNew from './pages/patienten/PatientenNew/PatientenNew';
import NachrichtenList from './pages/nachrichten/NachrichtenList/NachrichtenList';
import NachrichtenShow from './pages/nachrichten/NachrichtenShow/NachrichtenShow';
import VerifCodesList from './pages/verifcodes/VerifCodesList/VerifCodesList';
import VerifCodesNew from './pages/verifcodes/VerifCodesNew/VerifCodesNew';
import UserList from './pages/benutzerverwaltung/UserList/UserList';
import UserInfo from './pages/benutzerverwaltung/UserInfo/UserInfo';

const App = (): JSX.Element => (
  <Router>
    <Route path="/login">
      <LoginRegister />
    </Route>
    <Route exact path="/patienten/new">
      <ProtectedRoute>
        <PatientenNew />
      </ProtectedRoute>
    </Route>
    <Route path="/patienten/show/:id">
      <ProtectedRoute>
        <PatientenInfo />
      </ProtectedRoute>
    </Route>
    <Route exact path="/patienten">
      <ProtectedRoute>
        <PatientenList />
      </ProtectedRoute>
    </Route>
    <Route path="/nachrichten/show/:id">
      <ProtectedRoute>
        <NachrichtenShow />
      </ProtectedRoute>
    </Route>
    <Route exact path="/nachrichten">
      <ProtectedRoute>
        <NachrichtenList />
      </ProtectedRoute>
    </Route>
    <Route exact path="/verifcodes/new">
      <ProtectedRoute>
        <VerifCodesNew />
      </ProtectedRoute>
    </Route>
    <Route exact path="/verifcodes">
      <ProtectedRoute>
        <VerifCodesList />
      </ProtectedRoute>
    </Route>
    <Route path="/benutzerverwaltung/show/:id">
      <ProtectedRoute>
        <UserInfo />
      </ProtectedRoute>
    </Route>
    <Route exact path="/benutzerverwaltung">
      <ProtectedRoute>
        <UserList />
      </ProtectedRoute>
    </Route>
    <Route path="/einstellungen">
      <ProtectedRoute>
        <Einstellungen />
      </ProtectedRoute>
    </Route>
    <Route exact path="/">
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    </Route>
  </Router>
);

export default App;
