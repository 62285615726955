import React from 'react';

type Props = {
  currentStep: number,
  previousAction: () => void,
  changeContainer: (val: string) => void,
};

const PreviousButton = ({
  currentStep,
  previousAction,
  changeContainer,
}: Props): JSX.Element => (
  <button
    className="login-button prevnext-button float-left"
    type="button"
    onClick={(): void =>
      currentStep === 1 ? changeContainer('select') : previousAction()
    }
  >
    Zurück
  </button>
);
export default PreviousButton;
