import React from 'react';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { Patient } from '@customTypes/Patient';
import { prettifyDate } from '@/useful_functions';
import useAuthStore from '@/stores/authStore';
import { Link } from 'react-router-dom';

type Props = {
  patient: Patient,
};

const ListItem = ({ patient }: Props): JSX.Element => {
  const { jwtContent } = useAuthStore();
  const unread =
    (patient.meta.unreadConversation && jwtContent.userRole === 'viewer') ||
    (patient.meta.unreadConversationAdmin && jwtContent.userRole === 'admin');
  return (
    <tr className={unread ? 'bold' : ''} key={patient.patientenNummer}>
      <td width="20%">
        {patient.patientenNummer}
        {unread && <BsFillEnvelopeFill className="unread-icon ml-2" />}
      </td>
      <td width="25%">{patient.info.intPatientenNummer}</td>
      <td width="10%">
        <Link
          className="btn btn-primary"
          to={`/nachrichten/show/${patient._id}`}
        >
          Öffnen
        </Link>
      </td>
      <td width="20%">
        {prettifyDate(
          patient.conversation[patient.conversation.length - 1].timestamp,
        )}
      </td>
    </tr>
  );
};

export default ListItem;
