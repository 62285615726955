import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col } from 'react-bootstrap';
import SelectPrompt from './Select_Container/SelectContainer';
import LoginForm from './Login_Container/LoginContainer';
import RegisterForm from './Register_Container/RegisterContainer';
import stillLoggedIn from '../../stillLoggedIn';

import './LoginRegisterContainer.css';

const RegisterLoginContainer = (): JSX.Element => {
  const [container, setContainer] = useState('select');
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    stillLoggedIn()
      .then(() => setRedirect(true))
      .catch(() => setRedirect(false));
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <Row className="main-row">
        <Col lg={7} className="left-container">
          <img
            src="/assets/logo.png"
            className="smile-logo"
            alt="Champions Smile Logo"
            width="35%"
          />
        </Col>
        <Col lg={5} className="right-container">
          <TransitionGroup className="login-container">
            {container === 'select' && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <SelectPrompt changeContainer={setContainer} />
              </CSSTransition>
            )}
            {container === 'login' && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <LoginForm changeContainer={setContainer} />
              </CSSTransition>
            )}
            {container === 'register' && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <RegisterForm changeContainer={setContainer} />
              </CSSTransition>
            )}
          </TransitionGroup>
          <div className="login-footer">© Champions-Implants GmbH - 2024</div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterLoginContainer;
