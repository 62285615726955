import { User } from '@customTypes/User';
import axios from 'axios';
import { apiHost } from '../../../../env_vars';

const submitUserEdit = async (jwt: string, editedUser: User): Promise<void> => {
  const url = `${apiHost}/user/${editedUser._id}`;
  const formattedUser = { ...editedUser };
  // @ts-ignore
  delete formattedUser.conversations;
  // @ts-ignore
  delete formattedUser.patients;
  // @ts-ignore
  delete formattedUser.dateCreated;
  // @ts-ignore
  delete formattedUser.lastLogin;
  // @ts-ignore
  delete formattedUser.role;
  const options = {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.patch(url, editedUser, options);
};

export default submitUserEdit;
