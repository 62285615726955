import { apiHost } from '@/env_vars';
import { User } from '@customTypes/User';

/*
    fetchUser()
    This function is  used to fetch a user from the backend
*/

const fetchUser = async (userId: string, jwt: string): Promise<User> => {
  const url = `${apiHost}/user/${userId}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error('Could not fetch User');
  }
  const parsedResponse = await response.json();
  return parsedResponse;
};

export default fetchUser;
