const validateCode = (email: string, kundenNummer: string): string[] => {
  const validationErrors = [];
  // Email Check
  if (email.length < 5) {
    validationErrors.push('email');
  }
  if (
    !email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
    )
  ) {
    validationErrors.push('email');
  }
  // Kundenummer Check
  if (kundenNummer === '') {
    validationErrors.push('kundenNummer');
  }

  return validationErrors;
};

export default validateCode;
