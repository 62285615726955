import { apiHost } from '@/env_vars';

const closeCase = async (
  jwt: string,
  patientId: string,
  reason: string,
): Promise<void> => {
  const url = `${apiHost}/patient/${patientId}/close`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify({
      closeCaseReason: reason,
    }),
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error('Error rejecting image');
  }
};

export default closeCase;
