import useNewPatientStore from '@/stores/newPatientStore';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const TreatmentType = (): JSX.Element => {
  const {
    treatmentType,
    updateTreatmentType,
    anwenderBemerkungen,
    updateAnwenderBemerkungen,
    updateCurrentStep,
  } = useNewPatientStore();
  const [showHint, setShowHint] = useState<boolean>(false);

  const nextStep = (): void => {
    if (
      !['Oberkiefer', 'Unterkiefer', 'Ober & Unterkiefer'].includes(
        treatmentType,
      )
    ) {
      return setShowHint(true);
    }
    setShowHint(false);
    return updateCurrentStep(3);
  };
  return (
    <div className="patienten-step step1">
      <div>
        <h5>Behandlungsart:</h5>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-4 p-3">
          <img
            className="img-fluid"
            src="/treatmentType/oberkiefer.png"
            alt="Oberkiefer Grafik"
          />
          <div className="m-3 pl-3 p-2 condition-label">
            <Form.Check
              type="radio"
              name="treatmentType"
              value="Oberkiefer"
              checked={treatmentType === 'Oberkiefer'}
              onClick={(): void => updateTreatmentType('Oberkiefer')}
              id="oberkiefer"
              label="Oberkiefer"
            />
          </div>
        </div>
        <div className="col-4 p-3">
          <img
            className="img-fluid"
            src="/treatmentType/unterkiefer.png"
            alt="Oberkiefer Grafik"
          />
          <div className="m-3 pl-3 p-2 condition-label">
            <Form.Check
              type="radio"
              name="treatmentType"
              value="Unterkiefer"
              checked={treatmentType === 'Unterkiefer'}
              onClick={(): void => updateTreatmentType('Unterkiefer')}
              id="unterkiefer"
              label="Unterkiefer"
            />
          </div>
        </div>
        <div className="col-4 p-3">
          <img
            className="img-fluid"
            src="/treatmentType/voll.png"
            alt="Oberkiefer Grafik"
          />
          <div className="m-3 pl-3 p-2 condition-label">
            <Form.Check
              type="radio"
              name="treatmentType"
              value="Ober & Unterkiefer"
              checked={treatmentType === 'Ober & Unterkiefer'}
              onClick={(): void => updateTreatmentType('Ober & Unterkiefer')}
              id="ober-unterkiefer"
              label="Ober & Unterkiefer"
            />
          </div>
        </div>
        <div className="col-6 p-3 text-center">
          <Form.Group className="mb-3" controlId="anwenderBemerkungen">
            <Form.Label>Bemerkungen / Behandlungswünsche: </Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              onChange={(e): void => updateAnwenderBemerkungen(e.target.value)}
              value={anwenderBemerkungen}
              name="anwenderBemerkungen"
            />
          </Form.Group>
        </div>
      </div>
      {showHint === true && (
        <div className="text-center py-3 m-2">
          <div className="w-50 bg-hint-alert mx-auto py-3">
            <h4>Bitte Wählen sie eine Behandlungsart aus !</h4>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-around">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={(e): void => {
            e.preventDefault();
            updateCurrentStep(1);
          }}
        >
          Zurück
        </button>
        <button type="button" className="btn btn-primary" onClick={nextStep}>
          Weiter
        </button>
      </div>
    </div>
  );
};

export default TreatmentType;
