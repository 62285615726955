import { apiHost } from '@/env_vars';
import usePatientInfoStore from '@/stores/patientInfoStore';
import React from 'react';
import { Modal } from 'react-bootstrap';

const ShowImageModal = (): JSX.Element => {
  const { showImageModal, hideImageModal, imageModalUrl } =
    usePatientInfoStore();
  return (
    <Modal
      show={showImageModal}
      onHide={(): void => hideImageModal()}
      size="xl"
      className=""
      centered
    >
      <Modal.Body className="text-center">
        {showImageModal && (
          <img
            src={`${apiHost}/${imageModalUrl}`}
            style={{
              maxHeight: '80vh',
              margin: '0 auto',
            }}
            className="img-fluid"
            alt="Bild Konnte nicht Geladen werden !"
          />
        )}
      </Modal.Body>
      <Modal.Footer className="text-center justify-content-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={(): void => hideImageModal()}
        >
          Schliessen
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowImageModal;
