import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

type Props = {
  show: boolean,
  setShow: (show: boolean) => void,
  declineReason: string,
  setDeclineReason: (reason: string) => void,
  submitDeclineImage: (reason: string) => void,
};

const RejectImageModal = ({
  show,
  setShow,
  declineReason,
  setDeclineReason,
  submitDeclineImage,
}: Props): JSX.Element => {
  const [declineInvalid, setDeclineInvalid] = useState(false);

  const validateDecline = (): void => {
    if (declineReason === '') {
      setDeclineInvalid(true);
      return;
    }
    setDeclineInvalid(false);
    submitDeclineImage(declineReason);
  };

  const closeDeclineModal = (): void => {
    setShow(false);
    setDeclineReason('');
    setDeclineInvalid(false);
  };
  return (
    <Modal show={show} onHide={closeDeclineModal} centered>
      <Modal.Header>
        <span>Bild Ablehnen</span>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div>
          <div>
            <Form.Group>
              <Form.Label>Ablehnungsgrund</Form.Label>
              <Form.Control
                type="text"
                value={declineReason}
                name="ablehnungsgrund"
                onChange={(e): void => {
                  setDeclineReason(e.target.value);
                }}
                className={declineInvalid ? 'is-invalid' : ''}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben sie einen ablehnungsgrund ein !
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center justify-content-center">
        <div>
          <button
            type="button"
            className="btn btn-secondary mx-3"
            onClick={closeDeclineModal}
          >
            Zurück
          </button>
          <button
            type="button"
            className="btn btn-primary mx-3"
            onClick={(): void => validateDecline()}
          >
            Senden
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectImageModal;
