import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';
import { Modal } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import postAuftragsfreigabe from '../functions/postAuftragsfreigabe';

const AuftragsfreigabeButton = (): JSX.Element | undefined => {
  const [showAcceptModal, setShowAcceptModal] = React.useState<boolean>(false);

  const { patient, reloadPatient } = usePatientInfoStore();
  const { jwt, updateShowLoadingModal } = useAuthStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  const handleAcceptCase = async (): Promise<void> => {
    try {
      setShowAcceptModal(false);
      updateShowLoadingModal(true);
      await postAuftragsfreigabe(jwt, patient._id);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
      toast.success('Auftragsfreigabe wurde erteilt');
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Fehler beim Erteilen der Auftragsfreigabe');
    }
  };

  // Render only if we are admin and we havent yet given the Auftragsfreigabe
  if (patient.meta.caseTreatable && !patient.meta.auftragsFreigabeAnimation) {
    return (
      <>
        <div className="my-2">
          <button
            type="button"
            className="btn btn-primary mx-2"
            onClick={(): void => setShowAcceptModal(true)}
          >
            Auftragsfreigabe - Animation Erteilen
          </button>
        </div>
        {/*
         * Auftragsfreifgabe Modal
         */}
        <Modal
          centered
          show={showAcceptModal}
          onHide={(): void => setShowAcceptModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Fall Annehmen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <p>
                Sind sie sicher dass sie die Auftragsfreigabe für den Fall{' '}
                <b>{patient.patientenNummer}</b> erteilen wollen?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <div>
              <button
                type="button"
                className="btn btn-secondary mx-3"
                onClick={(): void => {
                  setShowAcceptModal(false);
                }}
              >
                Zurück
              </button>
              <button
                type="button"
                className="btn btn-success mx-3"
                onClick={handleAcceptCase}
              >
                Erteilen
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  return undefined;
};

export default AuftragsfreigabeButton;
