import { User } from '../../../types/User';
import { apiHost } from '../../env_vars';

/*
    StillLoggedIn()
    Is a short function to check if our JWT exists and is valid
*/
const fetchUser = async (userId: string, jwt: string): Promise<User> => {
  const url = `${apiHost}/user/${userId}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error('Error Fetching User Profile');
  }
  const parsedResponse = await response.json();
  return parsedResponse;
};

export default fetchUser;
