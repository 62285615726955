import axios from 'axios';
import { apiHost } from '../../../../env_vars';

type VerifCodeObject = {
  email: string,
  notiz: string,
  kundenNummer: string,
};

const postCode = async (
  { email, notiz, kundenNummer }: VerifCodeObject,
  jwt: string,
): Promise<void> => {
  const url = `${apiHost}/registerKey`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.post(
    url,
    {
      email,
      notiz,
      kundenNummer,
    },
    options,
  );
};

export default postCode;
