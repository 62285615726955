import { User } from '@customTypes/User';

const validateUserEdits = (user: User, editedUser: User): string[] => {
  const validationResult: string[] = [];

  /*
    Kundennummer
  */
  if (user.kundennummer !== editedUser.kundennummer) {
    if (editedUser.kundennummer.length !== 5) {
      validationResult.push('kundennummer');
    }
  }
  /*
    Vorname
  */
  if (user.vorname !== editedUser.vorname) {
    if (editedUser.vorname.length < 3) {
      validationResult.push('vorname');
    }
  }
  /*
    Nachname
  */
  if (user.nachname !== editedUser.nachname) {
    if (editedUser.nachname.length < 3) {
      validationResult.push('nachname');
    }
  }
  /*
    E-Mail
  */
  if (user.email !== editedUser.email) {
    if (
      editedUser.email.length < 5 ||
      !editedUser.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
      )
    ) {
      validationResult.push('email');
    }
  }
  /*
    Telefon
  */
  if (user.telefon !== editedUser.telefon) {
    if (
      editedUser.telefon.length < 5 ||
      !editedUser.telefon.match('^[0-9+-]*$')
    ) {
      validationResult.push('telefon');
    }
  }
  /*
    Praxis Name
  */
  if (user.praxName !== editedUser.praxName) {
    if (editedUser.praxName.length < 3) {
      validationResult.push('praxName');
    }
  }
  /*
    Praxis Strasse
  */
  if (user.praxStrasse !== editedUser.praxStrasse) {
    if (editedUser.praxStrasse.length < 3) {
      validationResult.push('praxStrasse');
    }
  }
  /*
    Praxis PLZ
  */
  if (user.praxPLZ !== editedUser.praxPLZ) {
    if (editedUser.praxPLZ.length < 4) {
      validationResult.push('praxPLZ');
    }
  }
  /*
    Praxis Ort
  */
  if (user.praxOrt !== editedUser.praxOrt) {
    if (editedUser.praxOrt.length < 3) {
      validationResult.push('praxOrt');
    }
  }
  /*
    Praxis Scanner
  */
  if (user.praxOrt !== editedUser.praxOrt) {
    if (typeof editedUser.praxScanner !== 'boolean') {
      validationResult.push('praxScanner');
    }
  }
  /*
    Patienten Index
  */
  if (user.patientenIndex !== editedUser.patientenIndex) {
    if (editedUser.patientenIndex < user.patientenIndex) {
      validationResult.push('patientenIndex');
    }
  }
  return validationResult;
};

export default validateUserEdits;
