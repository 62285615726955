import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Form } from 'react-bootstrap';
import RegisterStep1 from './RegisterStep1';
import RegisterStep2 from './RegisterStep2';
import RegisterStep3 from './RegisterStep3';
import RegisterStep4 from './RegisterStep4';
import BackToLogin from './BackToLogin';
import NextButton from './NextButton';
import PreviousButton from './PreviousButton';
import SubmitButton from './SubmitButton';
import validateStep1 from './Validation_Logic/validateStep1';
import { FormType, emptyForm } from './types/FormTypes';
import validateStep2 from './Validation_Logic/validateStep2';
import validateStep3 from './Validation_Logic/validateStep3';
import validateStep4 from './Validation_Logic/validateStep4';
import postSubmit from './postSubmit';

type Props = {
  changeContainer: (val: string) => void,
};

const RegisterForm = ({ changeContainer }: Props): JSX.Element => {
  const [step, setStep] = useState(1);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [values, setValues] = useState<FormType>({ ...emptyForm });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const previousStep = (): void => {
    setStep(step > 0 ? step - 1 : 0);
  };

  const nextStep = async (): Promise<void> => {
    setIsValidating(true);
    setValidationErrors([]);
    let errors: string[] = [];

    // Perform validation logic for each step
    switch (step) {
      case 1:
        errors = await validateStep1(values);
        break;
      case 2:
        // Validation logic for step 2
        errors = validateStep2(values);
        break;
      case 3:
        // Validation logic for step 3
        errors = validateStep3(values);
        break;
      case 4:
        // Validation logic for step 4
        errors = validateStep4(values);
        break;
      default:
        break;
    }

    // Proceed to the next step only if validation passes
    setValidationErrors(errors);
    if (errors.length === 0) {
      setStep(step >= 3 ? 4 : step + 1);
      setIsValidating(false);
    } else {
      setIsValidating(false);
    }
  };

  const submit = async (): Promise<void> => {
    setSubmitErrorMessage('');
    const result = await postSubmit(values);
    if (result !== '') {
      setSubmitErrorMessage(result);
    } else {
      setStep(5);
    }
  };

  return (
    <div className="register-form-container">
      <div>
        <h1 className="form-header">Registrieren</h1>
        {step < 5 && (
          <span>
            Schritt
            {step}
            /4
          </span>
        )}
      </div>
      <hr className="w-75" />
      <Form className="login-form" onSubmit={(e): void => e.preventDefault()}>
        <TransitionGroup>
          {step === 1 && (
            <CSSTransition classNames="slider-right" timeout={1000}>
              <RegisterStep1
                validation={validationErrors}
                handleChange={handleChange}
                formValues={values}
              />
            </CSSTransition>
          )}
          {step === 2 && (
            <CSSTransition classNames="slider-right" timeout={1000}>
              <RegisterStep2
                validation={validationErrors}
                handleChange={handleChange}
                formValues={values}
              />
            </CSSTransition>
          )}
          {step === 3 && (
            <CSSTransition classNames="slider-right" timeout={1000}>
              <RegisterStep3
                validation={validationErrors}
                handleChange={handleChange}
                formValues={values}
              />
            </CSSTransition>
          )}
          {step === 4 && (
            <CSSTransition classNames="slider-right" timeout={1000}>
              <RegisterStep4
                validation={validationErrors}
                handleChange={handleChange}
                formValues={values}
                submitErrorMessage={submitErrorMessage}
              />
            </CSSTransition>
          )}
          {step === 5 ? (
            <BackToLogin goToLogin={(): void => changeContainer('login')} />
          ) : (
            <div>
              {isValidating ? (
                <div className="loader mx-auto mt-3" />
              ) : (
                <div className="pt-2">
                  <PreviousButton
                    currentStep={step}
                    previousAction={previousStep}
                    changeContainer={changeContainer}
                  />
                  {step < 4 ? (
                    <NextButton nextAction={nextStep} />
                  ) : (
                    <SubmitButton submit={submit} />
                  )}
                </div>
              )}
            </div>
          )}
        </TransitionGroup>
      </Form>
    </div>
  );
};

export default RegisterForm;
