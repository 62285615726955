import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useNewPatientStore from '@/stores/newPatientStore';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import summarySubmit from './functions/summarySubmit';

const Summary = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const {
    images,
    imageKeys,
    intPatNr,
    geburtsdatum,
    geschlecht,
    overjet,
    overbite,
    treatmentType,
    anwenderBemerkungen,
    updateCurrentStep,
    currentStep,
  } = useNewPatientStore();
  const [newPatientId, setNewPatientId] = useState<string>('');

  const handleSubmit = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      const patId = await summarySubmit(jwt);
      setNewPatientId(patId);
      updateShowLoadingModal(false);
    } catch (error) {
      toast.error('Fehler beim Speichern der Daten !');
      updateShowLoadingModal(false);
    }
  };

  if (newPatientId !== '') {
    return <Redirect to={`/patienten/show/${newPatientId}`} />;
  }

  return (
    <div>
      <div className="row">
        <div className="patienten-info col-4">
          <h2 className="header">Zusamenfassung: </h2>
        </div>
      </div>
      <div className="row my-3">
        <div className="patienten-info col-5">
          <h4>Informationen:</h4>
          <div className="info-item">
            <b>Interne Patienten Nummer: </b>
            {intPatNr}
          </div>
          <div className="info-item">
            <b>Geburtsdatum: </b>
            {geburtsdatum}
          </div>
          <div className="info-item">
            <b>Geschlecht: </b>
            {geschlecht}
          </div>
          <div className="info-item">
            <b>Overjet: </b>
            {overjet}
          </div>
          <div className="info-item">
            <b>Overbite: </b>
            {overbite}
          </div>
          <div className="info-item">
            <b>Behandlungsart: </b>
            {treatmentType}
          </div>
          <div className="info-item">
            <b>Behandlungbemerkungen: </b>
            {anwenderBemerkungen !== ''
              ? anwenderBemerkungen
              : 'Keine Bemerkungen'}
          </div>
          <div className="p-3">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(): void => updateCurrentStep(2)}
            >
              Bearbeiten
            </button>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="patienten-info col-12">
          <h4>Dateien:</h4>
          <div className="row">
            {imageKeys.map((imageKey) => (
              <div className="col-4">
                <div className="datei-thumb text-center row">
                  <div className="col-8 p-3">
                    <img
                      className="img-fluid"
                      src={images[imageKey].imagePreview}
                      alt="Konnte Bild nicht Laden !"
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <div>
                      <span className="datei-header">
                        {images[imageKey].name}
                      </span>
                      <div className="row justify-content-center">
                        <div className="p-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={(): void =>
                              updateCurrentStep(images[imageKey].step)
                            }
                          >
                            Ändern
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row my-3 justify-content-center">
        <div className="patienten-info col-6">
          <div className="text-center d-flex justify-content-around">
            <button
              type="button"
              className="btn btn-primary mx-5"
              onClick={(): void => updateCurrentStep(currentStep - 1)}
            >
              Zurück
            </button>
            <button
              type="button"
              className="btn btn-primary mx-5"
              onClick={handleSubmit}
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};

export default Summary;
