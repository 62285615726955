import { apiHost } from '@/env_vars';
import useNewPatientStore from '@/stores/newPatientStore';
import { Patient } from '@customTypes/Patient';
import axios from 'axios';

const postNewPatient = async (jwt: string): Promise<Patient> => {
  const {
    geschlecht,
    geburtsdatum,
    intPatNr,
    overjet,
    overbite,
    treatmentType,
    anwenderBemerkungen,
  } = useNewPatientStore.getState();
  // Submitting Patient to get _id
  const newPatient = {
    geschlecht,
    geburtsdatum,
    intPatientenNummer: intPatNr,
    overjet,
    overbite,
    behandlungsart: treatmentType,
    anwenderBemerkungen,
  };
  const url = `${apiHost}/patient`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await axios.post(url, newPatient, options);
  return response.data;
};

export default postNewPatient;
