import axios from 'axios';
import { apiHost } from '../../../../env_vars';

const submitPassword = async (
  userId: string,
  password: string,
  newPassword: string,
  jwt: string,
): Promise<void> => {
  const url = `${apiHost}/user/${userId}`;
  await axios.patch(
    url,
    { password, newPassword },
    {
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

export default submitPassword;
