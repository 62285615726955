import React, { useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Link, useParams } from 'react-router-dom';
import useAuthStore from '@/stores/authStore';
import usePatientInfoStore from '@/stores/patientInfoStore';
import Sidebar from '@/components/sidebar/Sidebar';
import { ToastContainer } from 'react-toastify';
import PatientAmpel from './Components/PatientAmpel/PatientAmpel';
import 'react-toastify/dist/ReactToastify.css';
import './PatientenInfo.css';
import PatientDetails from './Components/PatientDetails/PatientDetails';
import PatientBehandlungsplan from './Components/PatientBehandlungsPlan/PatientBehandlungsplan';
import fetchPatient from './functions/fetchPatient';
import PatientenHeader from './Components/PatientenHeader';
import TreatmentFinishedPictures from './Components/PatientImages/TreatmentFinishedImages';
import PatientenImages from './Components/PatientImages/PatientImages';
import ShowImageModal from './Components/ShowImageModal/ShowImageModal';
import UploadPictureModal from './Components/PatientImages/UploadPictureModal';
import AngebotModal from './Components/AngebotModal/AngebotModal';
import UploadStlModal from './Components/UploadSTLModal/UploadStlModal';
import UploadAttachmentModal from './Components/UploadAttachmentModal/UploadAttachmentModal.component';
import DeleteAttachmentModal from './Components/DeleteAttachmentModal/UploadAttachmentModal.component';
import PatientAttachments from './Components/PatientenAttachments/PatientenAttachments';

type RouteProps = {
  id: string,
};

const PatientenInfo = (): JSX.Element => {
  const { id } = useParams<RouteProps>();
  const { patient, updatePatient } = usePatientInfoStore();
  const { updateShowLoadingModal, jwt, jwtContent } = useAuthStore();

  useEffect(() => {
    // if patient is not loaded or patient id is not the same as the id in the url
    if (!patient || patient.patientenNummer !== id) {
      updateShowLoadingModal(true);
      // fetch patient and hide loading modal
      fetchPatient(id, jwt)
        .then((res) => {
          updatePatient(res);
          updateShowLoadingModal(false);
        })
        .catch(() => {
          updateShowLoadingModal(false);
        });
    }
  }, [id]);

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="dashboard" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div>
                {!patient ? (
                  <div>
                    <div className="row">
                      <div className="patienten-info col-4">
                        <h2 className="header">Laden...</h2>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <PatientenHeader />
                    <div className="row my-3">
                      <div className="patienten-info col-6">
                        <PatientDetails />
                      </div>
                      <div className="col-1" />
                      {patient.meta.recievedOffer ? (
                        <div className="patienten-info col-5">
                          <PatientBehandlungsplan />
                          {patient.attachments &&
                          patient.attachments.length > 0 ? (
                            <PatientAttachments />
                          ) : null}
                        </div>
                      ) : (
                        <div className="patienten-info col-5">
                          <PatientAmpel />
                          {patient.attachments &&
                          patient.attachments.length > 0 ? (
                            <PatientAttachments />
                          ) : null}
                        </div>
                      )}
                    </div>
                    {jwtContent.userRole !== 'dentworry' &&
                      patient.meta.hasAllTreatmentFinishedPictures && (
                        <TreatmentFinishedPictures />
                      )}
                    {jwtContent.userRole !== 'dentworry' && <PatientenImages />}
                    <div className="row my-3 justify-content-center">
                      <div className="patienten-info col-6">
                        <div className="text-center d-flex justify-content-center">
                          <Link
                            type="button"
                            className="btn btn-primary mx-5"
                            to="/patienten"
                          >
                            Zurück
                          </Link>
                        </div>
                      </div>
                    </div>
                    <ShowImageModal />
                    <UploadPictureModal />
                    <AngebotModal />
                    <UploadStlModal />
                    <UploadAttachmentModal />
                    {patient.attachments && patient.attachments.length > 0 ? (
                      <DeleteAttachmentModal />
                    ) : null}
                  </div>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PatientenInfo;
