import React, { useEffect, useState } from 'react';
import './NachrichtenList.css';
import useAuthStore from '@/stores/authStore';
import { Patient } from '@customTypes/Patient';
import fetchUser from '@/api/fetchUser';
import Sidebar from '@/components/sidebar/Sidebar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ListItem from './components/ListItem';

const NachrichtenList = (): JSX.Element => {
  const { jwt, jwtContent } = useAuthStore();
  const [patients, setPatients] = useState<Patient[]>([]);

  useEffect(() => {
    fetchUser(jwtContent.userId, jwt).then((user) => {
      const sortedPatients = user.patients.sort(
        (a, b) =>
          new Date(
            b.conversation[b.conversation.length - 1].timestamp,
          ).getTime() -
          new Date(
            a.conversation[a.conversation.length - 1].timestamp,
          ).getTime(),
      );
      setPatients(sortedPatients);
    });
  }, []);

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="nachrichten" />
      <div className="p-5" id="page-content-wrapper">
        <TransitionGroup className="h-100" style={{ minWidth: '1000px' }}>
          <CSSTransition classNames="slider-right" timeout={1000}>
            <div className="nachrichten-list">
              <h2 className="header">Nachrichten</h2>
              <div>
                <table id="header-table">
                  <thead className="m-5">
                    <tr>
                      <th style={{ width: '20%' }}>Patienten Nummer</th>
                      <th style={{ width: '25%' }}>Interne Patientennummer</th>
                      <th style={{ width: '10%' }}>Aktion</th>
                      <th style={{ width: '20%' }}>Letzte Änderung</th>
                    </tr>
                  </thead>
                </table>
                <div id="table-container">
                  <table id="body-table">
                    <tbody>
                      {patients.map((patient) => (
                        <ListItem patient={patient} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

export default NachrichtenList;
