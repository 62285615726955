const getImageNameByKey = (key: string): string => {
  switch (key) {
    // Initial Images
    case 'frontalSchlussbiss':
      return 'Frontalbild mit abgehaltenen Lippen und Schlussbiss';
    case 'lateralLinks':
      return 'Lateral Bild Links im Schlussbiss';
    case 'lateralRechts':
      return 'Lateral Bild Rechts im Schlussbiss';
    case 'oberkiefer':
      return 'Aufbild des kompletten Oberkiefers';
    case 'unterkiefer':
      return 'Aufbild des kompletten Unterkiefers';
    case 'gesichtGeschlossen':
      return 'Gesichts-Enface-Bild mit geschlossenem Mund';
    case 'gesichtLachen':
      return 'Gesichts-Enface-Bild mit lächelndem Mund';
    case 'lateralGesicht':
      return 'Laterale Gesichtsaufnahme';
    case 'panorama':
      return 'Panorama Röntgenaufnahme';

    // Treatment Finished Images
    case 'frontalSchlussbiss_END':
      return 'Frontalbild mit abgehaltenen Lippen und Schlussbiss';
    case 'lateralLinks_END':
      return 'Lateral Bild Links im Schlussbiss';
    case 'lateralRechts_END':
      return 'Lateral Bild Rechts im Schlussbiss';
    case 'oberkiefer_END':
      return 'Aufbild des kompletten Oberkiefers';
    case 'unterkiefer_END':
      return 'Aufbild des kompletten Unterkiefers';
    case 'panorama_END':
      return 'Panorama Röntgenaufnahme';
    default:
      return 'Unbekannt';
  }
};

export default getImageNameByKey;
