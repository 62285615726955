import useNewPatientStore from '@/stores/newPatientStore';
import useAuthStore from '@/stores/authStore';
import postNewPatient from './postNewPatient';
import submitImage from './submitImage';

const timeout = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const summarySubmit = async (jwt: string): Promise<string> => {
  const { updateProgressBar } = useAuthStore.getState();
  const { imageKeys, images } = useNewPatientStore.getState();

  // Post the Patient
  updateProgressBar({
    show: true,
    progressPercent: 0,
    title: 'Patient wird erstellt',
  });
  const newPatient = await postNewPatient(jwt);

  let submittedImages = 0;
  // We iterate over the imageKeys and submit the images
  // eslint-disable-next-line no-restricted-syntax
  for await (const imageKey of imageKeys) {
    const image = images[imageKey];
    if (image.uploadedPicture !== undefined) {
      // Update the Progress Bar
      updateProgressBar({
        show: true,
        progressPercent:
          submittedImages === 0 ? 10 : (submittedImages + 1) * 10,
        title: `Bild ${submittedImages + 1} wird hochgeladen`,
      });
      // Submit the Image
      await submitImage(jwt, newPatient._id, imageKey, image.uploadedPicture);
      // Increment the Counter
      submittedImages += 1;
    }
  }
  // Add a little delay to make the progress bar look nice
  updateProgressBar({ show: true, progressPercent: 100, title: 'Fertig' });
  await Promise.all([timeout(1000)]);
  updateProgressBar({ show: false, progressPercent: 0, title: '' });
  return newPatient._id;
};

export default summarySubmit;
