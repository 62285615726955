const validateSTLFiles = (files: File[]): string => {
  if (files.length === 0) {
    return 'Bitte wählen Sie mindestens eine Datei aus';
  }
  if (files.length > 4) {
    return 'Bitte laden Sie maximal 4 Dateien hoch';
  }
  let errorMessage = '';
  files.forEach((file) => {
    if (file.name.slice(-3) !== 'STL' && file.name.slice(-3) !== 'stl') {
      errorMessage =
        'Unbekanntes Dateiformat bitte wählen sie eine STL Datei aus';
    }
    if (file.size >= 150 * 1024 * 1024) {
      errorMessage =
        'Die Datei ist zu groß. Bitte wählen Sie eine Datei kleiner als 150 MB';
    }
  });
  return errorMessage;
};

export default validateSTLFiles;
