import { create } from 'zustand';
import { Patient } from '@customTypes/Patient';
import fetchPatient from '@/api/fetchPatient';
import useAuthStore from './authStore';

type State = {
  patient?: Patient,
  showImageModal: boolean,
  imageModalUrl: string,
  imageUploadModalInfo: {
    show: boolean,
    imageKey: string,
    imageName: string,
  },
  showAngebotModal: boolean,
  showUploadSTLModal: boolean,
  showUploadAttachmentModal: boolean,
  showDeleteAttachmentModal: boolean,
};

type Action = {
  updatePatient: (content: State['patient']) => void,
  hideImageModal: () => void,
  toggleImageModal: (val: State['imageModalUrl']) => void,
  updateImageUploadModalInfo: (content: State['imageUploadModalInfo']) => void,
  updateShowAngebotModal: (content: State['showAngebotModal']) => void,
  updateShowUploadSTLModal: (content: State['showUploadSTLModal']) => void,
  updateShowUploadAttachmentModal: (
    content: State['showUploadAttachmentModal'],
  ) => void,
  updateShowDeleteAttachmentModal: (
    content: State['showDeleteAttachmentModal'],
  ) => void,
  reloadPatient: (jwt: string, patientId: string) => Promise<void>,
};

const usePatientInfoStore = create<State & Action>((set) => ({
  patient: undefined,
  showImageModal: false,
  imageModalUrl: '',
  imageUploadModalInfo: {
    show: false,
    imageKey: '',
    imageName: '',
  },
  showAngebotModal: false,
  showUploadSTLModal: false,
  showUploadAttachmentModal: false,
  showDeleteAttachmentModal: false,
  updatePatient: (content: State['patient']): void =>
    set(() => ({ patient: content })),
  // hide the image modal and reset the url
  hideImageModal: (): void =>
    set(() => ({ showImageModal: false, imageModalUrl: '' })),
  // Show the image modal with the given url
  toggleImageModal: (val: State['imageModalUrl']): void => {
    set(() => ({ showImageModal: true, imageModalUrl: val }));
  },
  updateImageUploadModalInfo: (
    content: State['imageUploadModalInfo'],
  ): void => {
    set(() => ({ imageUploadModalInfo: content }));
  },
  updateShowAngebotModal: (content: State['showAngebotModal']): void => {
    set(() => ({ showAngebotModal: content }));
  },
  updateShowUploadSTLModal: (content: State['showUploadSTLModal']): void => {
    set(() => ({ showUploadSTLModal: content }));
  },
  updateShowUploadAttachmentModal: (
    content: State['showUploadAttachmentModal'],
  ): void => {
    set(() => ({ showUploadAttachmentModal: content }));
  },
  updateShowDeleteAttachmentModal: (
    content: State['showDeleteAttachmentModal'],
  ): void => {
    set(() => ({ showDeleteAttachmentModal: content }));
  },
  // Reload the patient from the backend
  reloadPatient: async (jwt: string, patientId: string): Promise<void> => {
    useAuthStore.getState().updateShowLoadingModal(true);
    const patient = await fetchPatient(patientId, jwt);
    set(() => ({ patient }));
    useAuthStore.getState().updateShowLoadingModal(false);
  },
}));

export default usePatientInfoStore;
