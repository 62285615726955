import React from 'react';
import Actions from './Components/Actions';
import Details from './Components/Details';

const PatientenDetails = (): JSX.Element => {
  return (
    <div>
      <h4>Informationen:</h4>
      <Details />
      <Actions />
    </div>
  );
};

export default PatientenDetails;
