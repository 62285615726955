import React from 'react';

import './PatientAmpel.css';
import usePatientInfoStore from '@/stores/patientInfoStore';

const PatientAmpel = (): JSX.Element => {
  const { patient } = usePatientInfoStore();

  const ampelList = [
    {
      name: 'Alle Bilder Eingereicht',
      value: patient?.meta.hasAllPictures,
    },
    {
      name: 'Alle Bilder wurden überprüft',
      value: patient?.meta.hasAllPicturesCorrect,
    },
    {
      name: 'Fall Behandelbar',
      value: patient?.meta.caseTreatable,
    },
    {
      name: 'Auftragsfreigabe Animation und Behandlungsplan',
      value: patient?.meta.auftragsFreigabeAnimation,
    },
    {
      name: '3D STL Datei Vorhanden',
      value: patient?.meta.stlUploaded,
    },
    {
      name: 'Animation und Behandlungsplan erhalten',
      value: patient?.meta.recievedOffer,
    },
  ];

  return (
    <div>
      <h4>Vorraussetzungen: </h4>
      {ampelList.map((ampelItem) => (
        <div className="ampel-item">
          <div className={ampelItem.value ? 'ampel-green' : 'ampel-red'} />
          <span> {ampelItem.name}</span>
        </div>
      ))}
    </div>
  );
};

export default PatientAmpel;
