import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Sidebar from '../../components/sidebar/Sidebar';
import EinstellungenMain from './EinstellungenMain/EinstellungenMain';
import PasswordChange from './PasswordChange/PasswordChange';

const Einstellungen = (): JSX.Element => {
  const [container, setContainer] = useState('main');
  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="einstellungen" />
      <div className="p-5" id="page-content-wrapper">
        <TransitionGroup className="h-100">
          {container === 'main' && (
            <CSSTransition classNames="slider-right" timeout={1000}>
              <EinstellungenMain changeContainer={setContainer} />
            </CSSTransition>
          )}
          {container === 'password' && (
            <CSSTransition classNames="slider-right" timeout={1000}>
              <PasswordChange changeContainer={setContainer} />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Einstellungen;
