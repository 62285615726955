import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';
import { apiHost } from '@/env_vars';

const DownloadZipButton = (): JSX.Element | undefined => {
  const { patient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  // Render only if we have uploaded the STL and given the Auftragsfreigabe
  if (patient.meta.stlUploaded && patient.meta.auftragsFreigabeAnimation) {
    return (
      <div>
        <a
          target="_blank"
          className="btn btn-primary mx-2"
          href={`${apiHost}/uploads/${patient._id}/${patient.patientenNummer}.zip`}
          rel="noreferrer"
        >
          ZIP Datei Herunterladen
        </a>
      </div>
    );
  }
  return undefined;
};

export default DownloadZipButton;
