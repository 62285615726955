import React from 'react';

type Props = {
  nextAction: () => void,
};

const NextButton = ({ nextAction }: Props): JSX.Element => (
  <button
    className="login-button prevnext-button float-right"
    type="button"
    onClick={nextAction}
  >
    Weiter
  </button>
);

export default NextButton;
