import { apiHost } from '@/env_vars';
import axios from 'axios';

const postDeclineOffer = async (
  jwt: string,
  patientId: string,
): Promise<void> => {
  const url = `${apiHost}/patient/${patientId}/offer`;
  await axios.post(
    url,
    { acceptOffer: false },
    { headers: { Authorization: `Bearer ${jwt}` } },
  );
};

export default postDeclineOffer;
