import React from 'react';
import { prettifyDate } from '@/useful_functions';
import usePatientInfoStore from '@/stores/patientInfoStore';

const Details = (): JSX.Element => {
  const { patient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return <div />;

  return (
    <div>
      {/* Allgemeine Infos */}
      <div className="info-item">
        <b>Interne Patienten Nummer: </b>
        {patient.info.intPatientenNummer}
      </div>
      <div className="info-item">
        <b>Geburtsdatum: </b>
        {prettifyDate(patient.info.geburtsdatum, true)}
      </div>
      <div className="info-item">
        <b>Geschlecht: </b>
        {patient.info.geschlecht}
      </div>
      <div className="info-item">
        <b>Overjet: </b>
        {patient.info.overjet} mm
      </div>
      <div className="info-item">
        <b>Overbite: </b>
        {patient.info.overbite} mm
      </div>
      <div className="info-item">
        <b>Gewünschte Behandlungsart: </b>
        {patient.treatment.desiredTreatmentType}
      </div>
      <div className="info-item">
        <b>Bemerkungen: </b>
        {patient.treatment.anwenderComments !== ''
          ? patient.treatment.anwenderComments
          : 'Keine Bemerkungen'}
      </div>
      <div className="info-item">
        <b>STL Scan: </b>
        {patient.meta.homeScan ? 'Eigener Scan' : 'Modelle / Abdrücke'}
      </div>
      {patient.meta.treatmentFinished &&
      patient.treatment.closeCaseReason !== '' ? (
        <div>
          <div className="info-item">
            <b>Fall Schliessungsgrund: </b>
            {patient.treatment.closeCaseReason}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="info-item">
        <b>Datum Hinzugefügt: </b>
        {prettifyDate(patient.dateCreated, true)}
      </div>
    </div>
  );
};

export default Details;
