import useNewPatientStore from '@/stores/newPatientStore';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

type Props = {
  pictureName:
    | 'frontalSchlussbiss'
    | 'lateralLinks'
    | 'lateralRechts'
    | 'oberkiefer'
    | 'unterkiefer'
    | 'gesichtGeschlossen'
    | 'gesichtLachen'
    | 'lateralGesicht'
    | 'panorama',
};

const PictureStep = ({ pictureName }: Props): JSX.Element => {
  const { currentStep, images, updateCurrentStep, updateImage, clearImage } =
    useNewPatientStore();
  const [hintText, setHintText] = useState<string>('');

  const validatePicture = (): void => {
    const image = images[pictureName];
    if (image.uploadedPicture === undefined) {
      return setHintText('Bitte wählen sie ein Bild aus');
    }
    if (
      image.uploadedPicture?.type !== 'image/png' &&
      image.uploadedPicture?.type !== 'image/jpeg'
    ) {
      return setHintText(
        'Unbekanntes Dateiformat. Bitte wählen sie eine JPEG oder PNG Datei aus !',
      );
    }
    if (image.uploadedPicture?.size >= 10000000) {
      return setHintText(
        'Die Datei ist zu groß. Bitte wählen sie eine Datei unter 10MB aus !',
      );
    }
    setHintText('');
    return updateCurrentStep(currentStep + 1);
  };

  return (
    <div className="patienten-step Picture-Step">
      <div className="p-3">
        <h3>
          Bild {images[pictureName].step - 2}
          /9
        </h3>
        <p>{images[pictureName].name}</p>
        <div className="text-center">
          <img
            className="img-fluid rounded mx-5"
            src={`/samplePictures/${images[pictureName].sample}.jpg`}
            alt="Beispiel-Bild"
            style={{ maxWidth: '400px' }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center m-4">
        <div>
          {images[pictureName].imagePreview ? (
            <div className="text-center">
              <h5>Ausgewähltes Bild:</h5>
              <div>
                <img
                  className="img-fluid"
                  alt="Datei nicht Lesbar bitte erneut auswählen"
                  src={images[pictureName].imagePreview}
                  style={{ maxWidth: '400px' }}
                />
              </div>
              <Button
                className="my-2"
                onClick={(): void => {
                  clearImage(pictureName);
                }}
              >
                Bild Wechseln
              </Button>
            </div>
          ) : (
            <Form.Group>
              <Form.Label>Bild Hochladen</Form.Label>
              <Form.Control
                id="uploadMedia"
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e.target.files) {
                    updateImage(e.target.files[0], pictureName);
                  }
                }}
              />
            </Form.Group>
          )}
        </div>
      </div>
      {hintText !== '' && (
        <div
          className="bg-hint-alert text-center mx-4 px-4 py-2 my-3"
          style={{ maxWidth: '500px' }}
        >
          <h5>{hintText}</h5>
        </div>
      )}
      <div className="d-flex justify-content-around">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={(): void => {
            updateCurrentStep(currentStep - 1);
          }}
        >
          Zurück
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={(): void => validatePicture()}
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default PictureStep;
