import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

const LoginSuccess = (): JSX.Element => {
  const [timeoutComplete, setTimeoutComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => setTimeoutComplete(true), 1500);
  });
  return (
    <div className="text-center login-form-container">
      <h5>Erfolgreich Eingeloggt</h5>
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <p>Sie werden nun weitergeleitet</p>
      {timeoutComplete && <Redirect to="/" />}
    </div>
  );
};

export default LoginSuccess;
