import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Sidebar from '../../components/sidebar/Sidebar';
import StatisticsWidget from './widgets/statistics-widget/statistics-widget';
import OffenePatienten from './widgets/offene-patienten/offene-patienten';
import './widgets/widgets.css';
import fetchUser from './fetchUser';
import { Patient } from '../../../types/Patient';
import useAuthStore from '../../stores/authStore';

const Dashboard = (): JSX.Element => {
  const { showLoadingModal, updateShowLoadingModal, jwtContent, jwt } =
    useAuthStore();
  const [offenePatienten, setOffenePatienten] = useState<Patient[]>([]);
  const [stats, setStats] = useState({
    offen: 0,
    abgeschlossen: 0,
    insgesamt: 0,
  });

  useEffect(() => {
    updateShowLoadingModal(true);
    fetchUser(jwtContent.userId, jwt).then((user) => {
      const sortedPatients = user.patients.sort(
        (a, b) =>
          new Date(
            b.statusHistory[b.statusHistory.length - 1].timestamp,
          ).getTime() -
          new Date(
            a.statusHistory[a.statusHistory.length - 1].timestamp,
          ).getTime(),
      );
      const tempArray: Patient[] = [];
      let offen = 0;
      let abgeschlossen = 0;
      sortedPatients.forEach((patient) => {
        if (patient.meta.treatmentFinished) {
          abgeschlossen += 1;
        } else {
          offen += 1;
          tempArray.push(patient);
        }
      });
      setOffenePatienten(tempArray);
      setStats({
        ...stats,
        offen,
        abgeschlossen,
      });
      updateShowLoadingModal(false);
    });
  }, []);

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="dashboard" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            {!showLoadingModal && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <div>
                  <div className="row w-100 align-items-center">
                    <StatisticsWidget numbers={stats} />
                  </div>
                  <div className="row">
                    <OffenePatienten offenePatienten={offenePatienten} />
                  </div>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
