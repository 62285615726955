import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormType } from './types/FormTypes';

type RegisterStep1Props = {
  formValues: FormType,
  validation: string[],
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

const RegisterStep1 = ({
  formValues,
  validation,
  handleChange,
}: RegisterStep1Props): JSX.Element => (
  <div className="step my-2">
    <Form.Group
      as={Row}
      validated={validation.includes('verifCode')}
      controlId="formHorizontalEmail"
    >
      <Form.Label>
        Bitte geben sie ihren Verifizierungscode ein um fortzufahren:
      </Form.Label>
      <Form.Control
        type="text"
        placeholder="Verifizierungscode"
        name="verifCode"
        value={formValues.verifCode}
        onChange={handleChange}
        className={validation.includes('verifCode') ? 'is-invalid' : ''}
      />
      <Form.Control.Feedback type="invalid">
        Wir konnten keinen Verifizierungscode mit ihren Angaben finden. Bitte
        stellen Sie sicher, dass sie den korrekten 10-stelligen Code eingegeben
        haben.
      </Form.Control.Feedback>
    </Form.Group>
  </div>
);

export default RegisterStep1;
