import { apiHost } from '@/env_vars';
import axios from 'axios';

const postAngebotModal = (
  jwt: string,
  patientId: string,
  angebot: {
    behandlungsart: string,
    behandlungszeit: string,
    animationsLink: string,
    behandlungsbemerkungen: string,
  },
): Promise<void> => {
  const url = `${apiHost}/patient/${patientId}/status`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios.post(
    url,
    {
      status: 'makeOffer',
      treatmentDuration: angebot.behandlungszeit,
      treatmentType: angebot.behandlungsart,
      treatmentComments: angebot.behandlungsbemerkungen,
      animationsLink: angebot.animationsLink,
    },
    options,
  );
};

export default postAngebotModal;
