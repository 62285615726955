import { PatientenImage } from '@customTypes/Patient';
import React from 'react';
import {
  BsBoxArrowInUp,
  BsCheckSquareFill,
  BsFillQuestionOctagonFill,
  BsFillXOctagonFill,
} from 'react-icons/bs';
import { apiHost } from '@/env_vars';
import useAuthStore from '@/stores/authStore';
import usePatientInfoStore from '@/stores/patientInfoStore';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import getImageNameByKey from './getImageNameByKey';
import approveImage from '../../functions/approveImage';
import RejectImageModal from './RejectImageModal';
import rejectImage from '../../functions/rejectImage';

type Props = {
  image: PatientenImage,
  imageKey: string,
};

const ImageThumbnail = ({ image, imageKey }: Props): JSX.Element => {
  const [declineReason, setDeclineReason] = React.useState('');
  const [showDeclineModal, setShowDeclineModal] = React.useState(false);
  const {
    toggleImageModal,
    patient,
    reloadPatient,
    updateImageUploadModalInfo,
  } = usePatientInfoStore();
  const { jwtContent, jwt, updateShowLoadingModal } = useAuthStore();

  const submitApproveImage = async (): Promise<void> => {
    updateShowLoadingModal(true);
    try {
      updateShowLoadingModal(false);
      if (!patient) throw new Error('Patient not found');
      await approveImage(jwt, patient?._id, imageKey);
      await reloadPatient(jwt, patient?._id);
      toast.success('Bild wurde akzeptiert');
    } catch (e) {
      updateShowLoadingModal(false);
      toast.error('Bild konnte nicht akzeptiert werden');
    }
  };

  const submitDeclineImage = async (): Promise<void> => {
    try {
      setShowDeclineModal(false);
      updateShowLoadingModal(false);
      if (!patient) throw new Error('Patient not found');
      await rejectImage(jwt, patient?._id, declineReason, imageKey);
      await reloadPatient(jwt, patient?._id);
      setDeclineReason('');
      toast.success('Bild wurde abgelehnt');
    } catch (e) {
      updateShowLoadingModal(false);
      setDeclineReason('');
      toast.error('Bild konnte nicht abgelehnt werden');
    }
  };

  return (
    <div className="col-6 col-xl-4">
      <div className="datei-thumb text-center row">
        <div className="col-8 p-3">
          {Object.entries(image).length > 2 ? (
            <img
              className="img-fluid"
              src={`${apiHost}/${image.minFilePath}?${Date.now()}`}
              style={{ cursor: 'pointer' }}
              alt="datei-miniature"
              onClick={(): void => toggleImageModal(image.path)}
            />
          ) : (
            <span>Kein Bild Vorhanden !</span>
          )}
        </div>
        <div className="col-4 d-flex align-items-center">
          <div>
            <span className="datei-header">{getImageNameByKey(imageKey)}</span>
            <div className="row justify-content-center">
              <div className="col-6 thumb-icon">
                {/*
                    Image Status Switch
                    */}
                {image.imageStatus.status === 'Pending' && (
                  <BsFillQuestionOctagonFill
                    className="checking"
                    data-tooltip-id={imageKey}
                    data-tooltip-content={image.imageStatus.text}
                  />
                )}
                {image.imageStatus.status === 'Approved' && (
                  <BsCheckSquareFill
                    className="verified"
                    data-tooltip-id={imageKey}
                    data-tooltip-content={image.imageStatus.text}
                  />
                )}
                {image.imageStatus.status === 'Rejected' && (
                  <div className="text-center">
                    <BsFillXOctagonFill
                      className="wrong d-block mt-2 mx-auto"
                      data-tooltip-id={imageKey}
                      data-tooltip-content={image.imageStatus.text}
                    />
                    <BsBoxArrowInUp
                      className="download d-block mt-2 mx-auto"
                      data-tooltip-id={imageKey}
                      data-tooltip-content="Neues Bild Hochladen"
                      onClick={(): void => {
                        updateImageUploadModalInfo({
                          show: true,
                          imageKey,
                          imageName: getImageNameByKey(imageKey),
                        });
                      }}
                    />
                  </div>
                )}
                <Tooltip id={imageKey} className="tooltip" />
              </div>
            </div>
            {image.imageStatus.status === 'Pending' &&
            jwtContent.userRole === 'admin' ? (
              <div className="w-100">
                <div className="thumb-icon">
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={submitApproveImage}
                  >
                    Akzeptieren
                  </button>
                </div>
                <div className="thumb-icon">
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={(): void => {
                      setShowDeclineModal(true);
                    }}
                  >
                    Ablehnen
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <RejectImageModal
        declineReason={declineReason}
        setDeclineReason={setDeclineReason}
        show={showDeclineModal}
        setShow={setShowDeclineModal}
        submitDeclineImage={submitDeclineImage}
      />
    </div>
  );
};

export default ImageThumbnail;
