import usePatientInfoStore from '@/stores/patientInfoStore';
import { prettifyFileSize } from '@/useful_functions';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import validateAttachment from './functions/validateAttachment';
import postAttachment from './functions/postAttachment';

const UploadAttachmentModal = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const {
    showUploadAttachmentModal,
    updateShowUploadAttachmentModal,
    patient,
    reloadPatient,
  } = usePatientInfoStore();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [hintText, setHintText] = useState<string>('');

  const closeForm = (): void => {
    setHintText('');
    setSelectedFile(undefined);
    updateShowUploadAttachmentModal(false);
  };

  const submitFiles = async (): Promise<void> => {
    try {
      if (!selectedFile) {
        setHintText('Bitte eine Datei auswählen');
        return;
      }
      const valResult = validateAttachment(selectedFile);
      setHintText(valResult);
      // If there are error messages, return
      if (valResult !== '') return;

      // If there is no patient, return
      if (!patient) return;
      updateShowLoadingModal(true);
      await postAttachment(jwt, patient._id, selectedFile);
      closeForm();
      toast.success('Anhang erfolgreich hochgeladen');
      reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
    } catch (e) {
      updateShowLoadingModal(false);
      closeForm();
      toast.error('Fehler beim Hochladen des Anhangs');
    }
  };

  return (
    <Modal
      show={showUploadAttachmentModal}
      backdrop="static"
      className=""
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h3>Anhang Hochladen </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mx-4 my-2">
          <div>
            <Form.Group>
              <Form.Label>Anhang Hochladen</Form.Label>
              <Form.Control
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e.target.files) {
                    setSelectedFile(e.target.files[0]);
                  }
                }}
              />
            </Form.Group>
          </div>
        </div>
        {selectedFile && (
          <div className="px-4">
            <span>
              <b>Ausgewählte Datei</b>
            </span>
            <div>
              {selectedFile.name} - Grösse:
              {prettifyFileSize(selectedFile.size)}
            </div>
          </div>
        )}
        {hintText !== '' && (
          <div
            className="bg-hint-alert text-center mx-4 px-4 py-2 my-3"
            style={{ maxWidth: '500px' }}
          >
            <h5>{hintText}</h5>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" type="button" onClick={closeForm}>
          Zurück
        </button>
        <button type="button" className="btn btn-primary" onClick={submitFiles}>
          Hochladen
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadAttachmentModal;
