import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FormType } from './types/FormTypes';

type RegisterStep3Props = {
  formValues: FormType,
  validation: string[],
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

const RegisterStep3 = ({
  formValues,
  validation,
  handleChange,
}: RegisterStep3Props): JSX.Element => (
  <div className="text-left my-2">
    <p>
      <i>Praxis Daten:</i>
    </p>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridPraxisName">
        <Form.Label>Praxis-Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Praxis-Name"
          name="praxName"
          value={formValues.praxName}
          onChange={handleChange}
          className={validation.includes('praxName') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben Sie einen gültigen Praxis-Namen ein
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group as={Col} controlId="formGridPraxisStrasse">
        <Form.Label>Straße/Nr.</Form.Label>
        <Form.Control
          type="text"
          placeholder="Straße/Nr."
          name="praxStrasse"
          value={formValues.praxStrasse}
          onChange={handleChange}
          className={validation.includes('praxStrasse') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben Sie eine gültige Straße ein
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Group as={Col} md="4" controlId="formGridPraxisPLZ">
        <Form.Label>PLZ</Form.Label>
        <Form.Control
          type="text"
          placeholder="Postleitzahl"
          name="praxPLZ"
          value={formValues.praxPLZ}
          onChange={handleChange}
          className={validation.includes('praxPLZ') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben Sie eine gültige PLZ ein
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="8" controlId="formGridPraxisOrt">
        <Form.Label>Ort</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ort"
          name="praxOrt"
          value={formValues.praxOrt}
          onChange={handleChange}
          className={validation.includes('praxOrt') ? 'is-invalid' : ''}
        />
        <Form.Control.Feedback type="invalid">
          Bitte geben Sie einen gültigen Ort ein
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="8" controlId="formGridPraxisScanner">
        <Form.Group controlId="formGridPraxisScanner">
          <Form.Label>Bevorzugte Scanart:</Form.Label>
          <Form.Control
            as="select"
            name="praxScanner"
            value={formValues.praxScanner}
            defaultValue="default"
            onChange={handleChange}
            className={validation.includes('praxScanner') ? 'is-invalid' : ''}
          >
            <option value="default" disabled>
              Bitte Auswählen
            </option>
            <option value="models">Modelle / Abdrücke</option>
            <option value="3dScan">Eigener 3D Scan</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Bitte Wählen Sie eine Option aus
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Group>
    </Form.Group>
  </div>
);

export default RegisterStep3;
