import React from 'react';
import './offene-patienten.css';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuthStore from '../../../../stores/authStore';
import { Patient } from '../../../../../types/Patient';
import { prettifyDate } from '../../../../useful_functions';

type Props = {
  offenePatienten: Patient[],
};

const OffenePatienten = ({ offenePatienten }: Props): JSX.Element => {
  const { jwtContent } = useAuthStore();
  return (
    <div className="col-10 widget-square" id="patientenTableContainer">
      <h3 className="header">Offene Patienten</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nummer</th>
            <th>
              {jwtContent.userRole === 'admin'
                ? 'Zahnarzt'
                : 'Interne Patientennummer'}
            </th>
            <th>Aktion</th>
            <th>Status</th>
            <th>Letzte Aktualisierung</th>
          </tr>
        </thead>
        <tbody>
          {offenePatienten.map((patient) => (
            <tr key={patient.patientenNummer}>
              <td>{patient.patientenNummer}</td>
              <td>
                {jwtContent.userRole === 'admin'
                  ? patient.createdBy.praxName
                  : patient.info.intPatientenNummer}
              </td>
              <td className="text-center">
                <Link
                  to={`/patienten/show/${patient._id}`}
                  className="btn btn-primary"
                >
                  Öffnen
                </Link>
              </td>
              <td>
                {
                  patient.statusHistory[patient.statusHistory.length - 1]
                    .message
                }
              </td>
              <td>
                {prettifyDate(
                  patient.statusHistory[patient.statusHistory.length - 1]
                    .timestamp,
                  true,
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OffenePatienten;
