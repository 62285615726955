import { User } from '@customTypes/User';
import axios from 'axios';
import { apiHost } from '../../../../env_vars';

const fetchUsers = async (jwt: string): Promise<User[]> => {
  const url = `${apiHost}/user`;
  const options = {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await axios.get(url, options);
  return response.data;
};

export default fetchUsers;
