import { apiHost } from '../../../../../env_vars';
import { Patient } from '../../../../../../types/Patient';
/*
    StillLoggedIn()
    Is a short function to check if our JWT exists and is valid
*/
const fetchPatient = async (
  patientId: string,
  jwt: string,
): Promise<Patient> => {
  const url = `${apiHost}/patient/${patientId}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error('Could not fetch Patient');
  }
  const parsedResponse = await response.json();
  return parsedResponse;
};

export default fetchPatient;
