import React, { useEffect, useState } from 'react';
import VerifCode from '@customTypes/VerifCode';
import Sidebar from '@/components/sidebar/Sidebar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useAuthStore from '@/stores/authStore';
import { Link } from 'react-router-dom';
import { prettifyDate } from '../../../useful_functions';
import fetchCodes from './functions/fetchCodes';
import deleteCode from './functions/deleteCode';
import './VerifCodesList.css';

const VerifCodesList = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const [codes, setCodes] = useState<VerifCode[]>([]);

  useEffect(() => {
    updateShowLoadingModal(true);
    fetchCodes(jwt)
      .then((fetchedCodes) => {
        setCodes(fetchedCodes);
      })
      .finally(() => updateShowLoadingModal(false));
  }, []);

  const handleDeleteCode = async (id: string): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      await deleteCode(id, jwt);
      const updatedCodes = await fetchCodes(jwt);
      setCodes(updatedCodes);
      updateShowLoadingModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
    }
  };

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="verifCodes" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div className="patienten-list">
                <h2 className="header">Verifizierungscodes</h2>
                <Link
                  className="btn btn-primary"
                  id="addPatientBtn"
                  to="/verifcodes/new"
                >
                  Hinzufügen
                </Link>
                <div>
                  <table id="header-table">
                    <thead className="m-5">
                      <tr>
                        <th style={{ width: '20%' }}>Code</th>
                        <th style={{ width: '15%' }}>Email</th>
                        <th style={{ width: '15%' }}>Kundennummer</th>
                        <th style={{ width: '20%' }}>Notiz</th>
                        <th style={{ width: '10%' }}>Aktion</th>
                        <th style={{ width: '20%' }}>Erstellt</th>
                      </tr>
                    </thead>
                  </table>
                  <div id="table-container">
                    <table id="body-table">
                      <tbody>
                        {codes.map((code) => (
                          <tr>
                            <td width="20%">{code.token}</td>
                            <td width="15%">{code.email}</td>
                            <td width="15%">{code.kundenNummer}</td>
                            <td width="20%">
                              {code.notiz && code.notiz !== ''
                                ? code.notiz
                                : 'Keine Notiz'}
                            </td>
                            <td width="10%">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(): Promise<void> =>
                                  handleDeleteCode(code._id)
                                }
                              >
                                Löschen
                              </button>
                            </td>
                            <td width="20%">
                              {prettifyDate(code.dateCreated)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default VerifCodesList;
