import { Modal, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import useAuthStore from '@/stores/authStore';
import usePatientInfoStore from '@/stores/patientInfoStore';
import closeCase from '../functions/postCloseCase';

const PatientenHeader = (): JSX.Element => {
  const [showCloseCaseModal, setShowCloseCaseModal] = useState(false);
  const [closeCaseReason, setCloseCaseReason] = useState('');
  const { jwtContent, updateShowLoadingModal, jwt } = useAuthStore();
  const { patient, reloadPatient } = usePatientInfoStore();

  const closeCaseAction = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      if (!patient?._id) throw new Error('Patient ID not found');
      await closeCase(jwt, patient?._id, closeCaseReason);
      await reloadPatient(jwt, patient?._id);
      updateShowLoadingModal(false);
      setShowCloseCaseModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
    }
  };

  // if we dont have a patient, we dont render anything
  if (!patient) return <div />;

  return (
    <>
      <div className="row">
        <div className="patienten-info col-6">
          <h2 className="header">Patient: {patient.patientenNummer}</h2>
          {patient.meta.caseClosed ? (
            <div className="infodot-red" />
          ) : (
            <div className="infodot-green" />
          )}
          {!patient.meta.caseClosed && jwtContent.userRole === 'admin' ? (
            <button
              type="button"
              className="btn btn-primary mx-3 my-1"
              onClick={(): void => {
                setShowCloseCaseModal(true);
              }}
            >
              Fall Schliessen
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal
        show={showCloseCaseModal}
        onHide={(): void => setShowCloseCaseModal(false)}
        size="xl"
        centered
      >
        <Modal.Body className="text-center">
          <div>
            <p>
              Sind sie sicher dass sie den Fall Schliessen möchten ? Diese
              Aktion ist irreversibel.
            </p>
            <Form.Group>
              <Form.Label>Bemerkungen: </Form.Label>
              <Form.Control
                type="text"
                value={closeCaseReason}
                name="Bemerkungen"
                onChange={(e): void => setCloseCaseReason(e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <div>
            <button
              type="button"
              className="btn btn-secondary mx-3"
              onClick={(): void => {
                setCloseCaseReason('');
                setShowCloseCaseModal(false);
              }}
            >
              Zurück
            </button>
            <button
              type="submit"
              className="btn btn-danger mx-3"
              onClick={(): Promise<void> => closeCaseAction()}
            >
              Fall Schliessen
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PatientenHeader;
