import { apiHost } from '@/env_vars';
import useAuthStore from '@/stores/authStore';
import usePatientInfoStore from '@/stores/patientInfoStore';
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

const submitImage = async (
  jwt: string,
  patientId: string,
  image: File,
  imageKey: string,
): Promise<void> => {
  useAuthStore.getState().updateShowLoadingModal(true);
  const formData = new FormData();
  if (!usePatientInfoStore.getState().patient) {
    throw new Error('Patient not found');
  }
  formData.append('_id', patientId);
  formData.append('imagePosition', imageKey);
  formData.append('file', image);
  const options: AxiosRequestConfig = {
    onUploadProgress: (data: AxiosProgressEvent): void => {
      useAuthStore.getState().updateProgressBar({
        show: true,
        title: 'Bild Hochladen',
        progressPercent: Math.round((100 * data.loaded) / (data.total || 1)),
      });
    },
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const response = await axios.post(
    `${apiHost}/imageUpload`,
    formData,
    options,
  );
  const { status } = response;
  if (status !== 201) {
    throw new Error('Error while uploading image');
  }
};

export default submitImage;
