import { FormType } from '../types/FormTypes';

const validateStep3 = (formValues: FormType): string[] => {
  const errors = [];

  // Praxis Name Check
  if (formValues.praxName.length < 3) {
    errors.push('praxName');
  }

  // Praxis Strasse Check
  if (formValues.praxStrasse.length < 3) {
    errors.push('praxStrasse');
  }

  // Praxis PLZ Check
  if (formValues.praxPLZ.length < 4) {
    errors.push('praxPLZ');
  }

  // Praxis Ort Check
  if (formValues.praxOrt.length < 3) {
    errors.push('praxOrt');
  }

  return errors;
};

export default validateStep3;
