import React from 'react';
import { User } from '@customTypes/User';
import { prettifyDate } from '../../../../useful_functions';

type Props = {
  user: User,
  setEditMode: (editMode: boolean) => void,
  showPasswordModal: (show: boolean) => void,
};

const UserDetails = ({
  user,
  setEditMode,
  showPasswordModal,
}: Props): JSX.Element => {
  return (
    <div>
      <h4>Informationen</h4>
      <div>
        <div className="info-item">
          <b>Kundennummer: </b>
          {user.kundennummer}
        </div>
        <div className="info-item">
          <b>Benutzername: </b>
          {user.benutzername}
        </div>
        <div className="info-item">
          <b>E-Mail: </b>
          {user.email}
        </div>
        <div className="info-item">
          <b>Telefon: </b>
          {user.telefon}
        </div>
        <div className="info-item">
          <b>Vorname: </b>
          {user.vorname}
        </div>
        <div className="info-item">
          <b>Nachname: </b>
          {user.nachname}
        </div>
        <div className="info-item">
          <b>Praxisname: </b>
          {user.praxName}
        </div>
        <div className="info-item">
          <b>Praxisstrasse: </b>
          {user.praxStrasse}
        </div>
        <div className="info-item">
          <b>Praxis PLZ: </b>
          {user.praxPLZ}
        </div>
        <div className="info-item">
          <b>Praxis Ort: </b>
          {user.praxOrt}
        </div>
        <div className="info-item">
          <b>Praxis Scanner: </b>
          {user.praxScanner ? 'Ja' : 'Nein'}
        </div>
        <div className="info-item">
          <b>Patienten Index: </b>
          {user.patientenIndex}
        </div>
        <div className="info-item">
          <b>Letzter Login: </b>
          {prettifyDate(user.lastLogin)}
        </div>
        <div className="info-item">
          <b>Account Erstellt: </b>
          {prettifyDate(user.dateCreated)}
        </div>
      </div>
      <h4 className="mt-3">Aktionen</h4>
      <div>
        <button
          type="button"
          className="btn btn-primary m-3"
          onClick={(): void => showPasswordModal(true)}
        >
          Passwort Ändern
        </button>
        <button
          type="button"
          className="btn btn-primary m-3"
          onClick={(): void => setEditMode(true)}
        >
          Daten Bearbeiten
        </button>
      </div>
    </div>
  );
};

export default UserDetails;
