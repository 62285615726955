import { create } from 'zustand';

type Image = {
  name: string,
  step: number,
  sample: string,
  uploadedPicture: File | undefined,
  imagePreview: string,
};

type State = {
  currentStep: number,
  intPatNr: string,
  geburtsdatum: string,
  geschlecht: string,
  overjet: string,
  overbite: string,
  treatmentType: string,
  anwenderBemerkungen: string,
  images: {
    frontalSchlussbiss: Image,
    lateralLinks: Image,
    lateralRechts: Image,
    oberkiefer: Image,
    unterkiefer: Image,
    gesichtGeschlossen: Image,
    gesichtLachen: Image,
    lateralGesicht: Image,
    panorama: Image,
  },
  imageKeys: Array<keyof State['images']>,
};

type Action = {
  updateCurrentStep: (currentStep: State['currentStep']) => void,
  updateIntPatNr: (intPatNr: State['intPatNr']) => void,
  updateGeburtsdatum: (geburtsdatum: State['geburtsdatum']) => void,
  updateGeschlecht: (geschlecht: State['geschlecht']) => void,
  updateOverjet: (overjet: State['overjet']) => void,
  updateOverbite: (overbite: State['overbite']) => void,
  updateTreatmentType: (treatmentType: State['treatmentType']) => void,
  updateAnwenderBemerkungen: (
    anwenderBemerkungen: State['anwenderBemerkungen'],
  ) => void,
  updateImage: (image: File, imageName: keyof State['images']) => void,
  clearImage: (imageName: keyof State['images']) => void,
};

const useNewPatientStore = create<State & Action>((set, get) => ({
  currentStep: 1,
  intPatNr: '',
  geburtsdatum: '',
  geschlecht: 'Männlich',
  overjet: '',
  overbite: '',
  treatmentType: '',
  anwenderBemerkungen: '',
  images: {
    frontalSchlussbiss: {
      name: 'Frontalbild mit abgehaltenen Lippen und Schlussbiss',
      step: 3,
      sample: 'FrontalSchlussbiss',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    lateralLinks: {
      name: 'Lateral Bild Links im Schlussbiss',
      step: 4,
      sample: 'LateralLinks',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    lateralRechts: {
      name: 'Lateral Bild Rechts im Schlussbiss',
      step: 5,
      sample: 'LateralRechts',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    oberkiefer: {
      name: 'Aufbild des kompletten Oberkiefers',
      step: 6,
      sample: 'Oberkiefer',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    unterkiefer: {
      name: 'Aufbild des kompletten Unterkiefers',
      step: 7,
      sample: 'Unterkiefer',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    gesichtGeschlossen: {
      name: 'Gesichts-Enface-Bild mit geschlossenem Mund',
      step: 8,
      sample: 'GesichtGeschlossen',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    gesichtLachen: {
      name: 'Gesichts-Enface-Bild mit lächelndem Mund',
      step: 9,
      sample: 'GesichtLachen',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    lateralGesicht: {
      name: 'Laterale Gesichtsaufnahme',
      step: 10,
      sample: 'LateralGesicht',
      uploadedPicture: undefined,
      imagePreview: '',
    },
    panorama: {
      name: 'Panorama Röntgenaufnahme',
      step: 11,
      sample: 'Panorama',
      uploadedPicture: undefined,
      imagePreview: '',
    },
  },
  imageKeys: [
    'frontalSchlussbiss',
    'lateralLinks',
    'lateralRechts',
    'oberkiefer',
    'unterkiefer',
    'gesichtGeschlossen',
    'gesichtLachen',
    'lateralGesicht',
    'panorama',
  ],
  updateImage: (image: File, imageName: keyof State['images']): void => {
    const { images } = get();
    images[imageName].uploadedPicture = image;
    images[imageName].imagePreview = URL.createObjectURL(image);
    set({ images });
  },
  clearImage: (imageName: keyof State['images']): void => {
    const { images } = get();
    images[imageName].uploadedPicture = undefined;
    images[imageName].imagePreview = '';
    set({ images });
  },
  updateCurrentStep: (currentStep: State['currentStep']): void => {
    set({ currentStep });
  },
  updateIntPatNr: (intPatNr: State['intPatNr']): void => set({ intPatNr }),
  updateGeburtsdatum: (geburtsdatum: State['geburtsdatum']): void =>
    set({ geburtsdatum }),
  updateGeschlecht: (geschlecht: State['geschlecht']): void =>
    set({ geschlecht }),
  updateOverjet: (overjet: State['overjet']): void => set({ overjet }),
  updateOverbite: (overbite: State['overbite']): void => set({ overbite }),
  updateTreatmentType: (treatmentType: State['treatmentType']): void =>
    set({ treatmentType }),
  updateAnwenderBemerkungen: (
    anwenderBemerkungen: State['anwenderBemerkungen'],
  ): void => set({ anwenderBemerkungen }),
}));

export default useNewPatientStore;
