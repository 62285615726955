import React from 'react';
import useAuthStore from '@/stores/authStore';
import usePatientInfoStore from '@/stores/patientInfoStore';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import postAcceptCase from '../functions/postAcceptCase';
import postRejectCase from '../functions/postRejectCase';

const AcceptRejectCaseButton = (): JSX.Element | undefined => {
  const [showAcceptModal, setShowAcceptModal] = React.useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = React.useState<boolean>(false);
  const [rejectCaseReason, setRejectCaseReason] = React.useState<string>('');
  const { patient, reloadPatient } = usePatientInfoStore();
  const { jwt, updateShowLoadingModal } = useAuthStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  const handleAcceptCase = async (): Promise<void> => {
    try {
      setShowAcceptModal(false);
      updateShowLoadingModal(true);
      await postAcceptCase(jwt, patient._id);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
      toast.success('Fall wurde angenommen');
    } catch (error) {
      toast.error('Fehler beim Annehmen des Falls');
      updateShowLoadingModal(false);
    }
  };

  const handleRejectCase = async (): Promise<void> => {
    try {
      setShowRejectModal(false);
      updateShowLoadingModal(true);
      await postRejectCase(jwt, patient._id, rejectCaseReason);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
      toast.success('Fall wurde abgelehnt');
    } catch (error) {
      toast.error('Fehler beim Ablehnen des Falls');
      updateShowLoadingModal(false);
    }
  };

  // Render only if we are admin and the Patient has all pictures correct
  if (patient.meta.hasAllPicturesCorrect && !patient.meta.caseTreatable) {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary mx-2"
          onClick={(): void => setShowAcceptModal(true)}
        >
          Fall Annehmen
        </button>
        <button
          type="button"
          className="btn btn-primary mx-2"
          onClick={(): void => setShowRejectModal(true)}
        >
          Fall Ablehnen
        </button>
        {/*
         * Accept Modal
         */}
        <Modal
          centered
          show={showAcceptModal}
          onHide={(): void => setShowAcceptModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Fall Annehmen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <p>
                Sind sie sicher dass sie den Fall{' '}
                <b>{patient.patientenNummer}</b> annehmen wollen?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <div>
              <button
                type="button"
                className="btn btn-secondary mx-3"
                onClick={(): void => {
                  setShowAcceptModal(false);
                }}
              >
                Zurück
              </button>
              <button
                type="button"
                className="btn btn-success mx-3"
                onClick={handleAcceptCase}
              >
                Annehmen
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/*
         *  Reject Modal
         */}
        <Modal
          centered
          show={showRejectModal}
          onHide={(): void => setShowRejectModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Fall Ablehnen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <p>
                Sind sie sicher dass sie den Fall{' '}
                <b>{patient.patientenNummer}</b> ablehnen wollen?
              </p>
              <Form.Group>
                <Form.Label>Ablehnungsgrund: </Form.Label>
                <Form.Control
                  type="text"
                  value={rejectCaseReason}
                  name="Bemerkungen"
                  onChange={(e): void => setRejectCaseReason(e.target.value)}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <div>
              <button
                type="button"
                className="btn btn-secondary mx-3"
                onClick={(): void => {
                  setShowRejectModal(false);
                }}
              >
                Zurück
              </button>
              <button
                type="button"
                className="btn btn-danger mx-3"
                onClick={handleRejectCase}
              >
                Ablehnen
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  return undefined;
};

export default AcceptRejectCaseButton;
