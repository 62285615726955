import React, { useState } from 'react';

import './PatientBehandlungsplan.css';
import usePatientInfoStore from '@/stores/patientInfoStore';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import AnimationsModal from './components/AnimationsModal';
import postAcceptOffer from './functions/postAcceptOffer';
import postDeclineOffer from './functions/postDeclineOffer';
import postAuftragsfreigabe from './functions/postAuftragsfreigabe';

const PatientBehandlungsPlan = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const { patient, reloadPatient } = usePatientInfoStore();
  const { jwtContent, jwt, updateShowLoadingModal } = useAuthStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return <div />;

  const handleAcceptOffer = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      await postAcceptOffer(jwt, patient._id);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
      toast.success('Angebot angenommen');
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Fehler beim annehmen des Angebots');
    }
  };

  const handleDeclineOffer = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      await postDeclineOffer(jwt, patient._id);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
      toast.success('Angebot abgelehnt');
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Fehler beim ablehnen des Angebots');
    }
  };

  const handleAuftragsfreigabe = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      await postAuftragsfreigabe(jwt, patient._id);
      await reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Fehler beim erteilen der Auftragsfreigabe');
    }
  };

  return (
    <div>
      <h4>Behandlungsplan</h4>
      <span>
        <b>Behandlungsdauer: </b>
        {patient.treatment.treatmentDuration}
        <br />
      </span>
      <span>
        <b>Behandlungsplan: </b>
        {patient.treatment.treatmentType}
        <br />
      </span>
      <span>
        <b>Behandlungsbemerkungen: </b>
        {patient.treatment.treatmentComments}
        <br />
      </span>
      <span>
        <b>Behandlungsstatus: </b>
        {patient.treatment.treatmentStatus}
        <br />
      </span>
      {patient.treatment.treatmentStatus === 'Angenommen' && (
        <span>
          <b>Auftragsfreigabe: </b>
          {patient.meta.auftragsFreigabe ? 'Ja' : 'Nein'}
          <br />
        </span>
      )}
      {patient.treatment.treatmentStatus === 'Abgeschlossen' && (
        <div className="ampel-item">
          <span>
            <b> Abschlussbilder Eingereicht</b>
          </span>
          <div
            className={
              patient.meta.hasAllTreatmentFinishedPictures
                ? 'ampel-green'
                : 'ampel-red'
            }
          />
        </div>
      )}
      {patient.treatment.treatmentStatus === 'Abgeschlossen' && (
        <div className="ampel-item">
          <span>
            {' '}
            <b>Abschlussbilder Überprüft</b>
          </span>
          <div
            className={
              patient.meta.hasAllTreatmentFinishedPicturesCorrect
                ? 'ampel-green'
                : 'ampel-red'
            }
          />
        </div>
      )}
      {patient.meta.hasAllTreatmentFinishedPictures && (
        <div>
          <span>
            <b>Overjet Behandlungsende: </b>
            {patient.treatment.overjetFinished} mm
            <br />
          </span>
          <span>
            <b>Overbite Behandlungsende: </b>
            {patient.treatment.overbiteFinished} mm
            <br />
          </span>
          <span>
            <b>Bemerkungen Behandlungsende: </b>
            {patient.treatment.treatmentFinishedCommentsAnwender}
            <br />
          </span>
        </div>
      )}
      <div className="my-3 mx-2">
        {patient.treatment.animationsLink.startsWith(
          'https://doctor.3dortho-plan.com/',
        ) ? (
          <a
            className="btn btn btn-primary mx-2"
            target="_blank"
            href={patient.treatment.animationsLink}
            rel="noreferrer"
          >
            Animation ansehen
          </a>
        ) : (
          <button
            type="button"
            className="btn btn btn-primary mx-2"
            onClick={(): void => setShowModal(true)}
          >
            Animation Ansehen
          </button>
        )}
      </div>
      {jwtContent.userRole === 'viewer' &&
      !patient.meta.anwenderRespondedToOffer &&
      patient.treatment.treatmentStatus === 'Ausstehend' ? (
        <div>
          <hr />
          <h5>Auf Angebot Antworten: </h5>
          <div className="my-3 mx-2">
            <button
              type="button"
              className="btn btn btn-primary mx-2"
              onClick={handleAcceptOffer}
            >
              Angebot Annehmen
            </button>
            <button
              type="button"
              className="btn btn btn-primary mx-2"
              onClick={handleDeclineOffer}
            >
              Angebot Ablehnen
            </button>
          </div>
        </div>
      ) : (
        <div />
      )}
      {jwtContent.userRole === 'admin' &&
      !patient.treatment.treatmentFinished ? (
        <div>
          <hr />
          <h5>Angebot: </h5>
          <div className="my-3 mx-2">
            {patient.treatment.treatmentStatus === 'Ausstehend' && (
              <button
                type="button"
                className="btn btn btn-primary mx-2"
                onClick={(): void => {}}
              >
                Bearbeiten
              </button>
            )}
            {!patient.meta.auftragsFreigabe &&
            patient.treatment.treatmentStatus === 'Angenommen' ? (
              <button
                type="button"
                className="btn btn btn-primary mx-2"
                onClick={handleAuftragsfreigabe}
              >
                Auftragsfreigabe Erteilen
              </button>
            ) : (
              <div />
            )}
          </div>
        </div>
      ) : (
        <div />
      )}
      <AnimationsModal
        animationsLink={patient.treatment.animationsLink}
        show={showModal}
        setShow={setShowModal}
      />
    </div>
  );
};

export default PatientBehandlungsPlan;
