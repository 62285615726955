import usePatientInfoStore from '@/stores/patientInfoStore';
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import postAngebotModal from './postAngebotModal';

const AngebotModal = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const { showAngebotModal, updateShowAngebotModal, patient, reloadPatient } =
    usePatientInfoStore();
  const [behandlungsart, setBehandlungsart] = useState<string>('Full');
  const [behandlungszeit, setBehandlungszeit] = useState<string>('');
  const [animationsLink, setAnimationsLink] = useState<string>('');
  const [behandlungsbemerkungen, setBehandlungsbemerkungen] =
    useState<string>('');
  const [validation, setValidation] = useState<string[]>([]);

  const closeForm = (): void => {
    setBehandlungsart('');
    setBehandlungszeit('');
    setAnimationsLink('');
    setBehandlungsbemerkungen('');
    updateShowAngebotModal(false);
  };

  const fillModal = (): void => {
    if (patient && patient.meta.recievedOffer && patient.treatment) {
      setBehandlungsart(patient?.treatment.treatmentType);
      setBehandlungszeit(patient?.treatment.treatmentDuration);
      setAnimationsLink(patient?.treatment.animationsLink);
      setBehandlungsbemerkungen(patient?.treatment.treatmentComments);
    }
  };

  const validateForm = (): string[] => {
    const errorArray = [];

    // Behandlungszeit Validation
    if (behandlungszeit === '') {
      errorArray.push('behandlungszeit');
    }

    // Behandlungsart Validation
    if (
      !['Full', 'Full 1 Kiefer', 'Lite', 'Lite 1 Kiefer'].includes(
        behandlungsart,
      )
    ) {
      errorArray.push('behandlungsart');
    }

    // Animationslink Validation
    if (animationsLink === '') {
      errorArray.push('animationsLink');
    }

    setValidation(errorArray);
    return errorArray;
  };

  const submitForm = async (): Promise<void> => {
    try {
      // Return if we have validation errors
      if (validateForm().length > 0) return;

      // Return if patient is not loaded
      if (!patient) return;

      updateShowLoadingModal(true);
      await postAngebotModal(jwt, patient?._id, {
        behandlungszeit,
        behandlungsart,
        animationsLink,
        behandlungsbemerkungen,
      });
      await reloadPatient(jwt, patient?._id);

      // After we are done, hide the loading modal and show a success toast
      updateShowLoadingModal(false);
      toast.success('Angebot erfolgreich gespeichert');
      closeForm();
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Es ist ein Fehler aufgetreten');
      closeForm();
    }
  };

  return (
    <div>
      <Modal
        show={showAngebotModal}
        centered
        onShow={(): void => fillModal()}
        onHide={(): void => updateShowAngebotModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Angebot {patient?.meta.recievedOffer ? 'Bearbeiten' : 'Erstellen'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Behandlungszeit:</Form.Label>
            <Form.Control
              type="text"
              name="behandlungszeit"
              value={behandlungszeit}
              onChange={(e): void => {
                setBehandlungszeit(e.target.value);
              }}
              className={
                validation.includes('behandlungszeit') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben sie eine Behandlungszeit ein!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Behandlungsart</Form.Label>
            <Form.Control
              as="select"
              name="behandlungsart"
              value={behandlungsart}
              onChange={(e): void => {
                setBehandlungsart(e.target.value);
              }}
              className={
                validation.includes('behandlungsart') ? 'is-invalid' : ''
              }
            >
              <option value="Full">
                Full (OK und UK mit Unbegrenzt Schienen)
              </option>
              <option value="Full 1 Kiefer">
                Full 1 (OK oder UK mit Unbegrenzt Schienen)
              </option>
              <option value="Lite">
                Lite (OK und UK mit maximal 18 Schienen)
              </option>
              <option value="Lite 1 Kiefer">
                Lite 1 (OK oder UK mit maximal 18 Schienen)
              </option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Bitte Wählen sie eine gültige Behandlungsart aus !
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Link zur Animation:</Form.Label>
            <Form.Control
              type="text"
              name="behandlungsbemerkungen"
              value={animationsLink}
              onChange={(e): void => {
                setAnimationsLink(e.target.value);
              }}
              className={
                validation.includes('animationsLink') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte Wählen sie einen gültigen Link ein !
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Bemerkungen:</Form.Label>
            <Form.Control
              type="text"
              name="behandlungsbemerkungen"
              value={behandlungsbemerkungen}
              onChange={(e): void => {
                setBehandlungsbemerkungen(e.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-secondary mx-3"
              onClick={closeForm}
            >
              Zurück
            </button>
            <button
              type="button"
              className="btn btn-primary mx-3"
              onClick={submitForm}
            >
              Speichern
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AngebotModal;
