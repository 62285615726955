import React, { useEffect, useState } from 'react';
import useAuthStore from '@/stores/authStore';
import { User } from '@customTypes/User';
import { toast } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Sidebar from '@/components/sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { prettifyDate } from '../../../useful_functions';
import fetchUsers from './functions/fetchUsers';
import './UserList.css';

const UserList = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const [users, setUsers] = useState<User[]>([]);

  const updateListing = async (): Promise<void> => {
    try {
      updateShowLoadingModal(true);
      const fetchedUsers = await fetchUsers(jwt);
      setUsers(fetchedUsers);
      updateShowLoadingModal(false);
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Fehler beim Laden der Daten');
    }
  };

  useEffect(() => {
    updateListing();
  }, []);

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="benutzerverwaltung" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div className="patienten-list">
                <h2 className="header">Benutzer</h2>
                <div>
                  <table id="header-table">
                    <thead className="m-5">
                      <tr>
                        <th style={{ width: '20%' }}>Kundennummer</th>
                        <th style={{ width: '30%' }}>Praxisname</th>
                        <th style={{ width: '15%' }}>Anzahl Fälle</th>
                        <th style={{ width: '20%' }}>Zuletzt Eingeloggt</th>
                        <th style={{ width: '15%' }}>Aktion</th>
                      </tr>
                    </thead>
                  </table>
                  <div id="table-container">
                    <table id="body-table">
                      <tbody>
                        {users.map((user) => (
                          <tr>
                            <td style={{ width: '20%' }}>
                              {user.kundennummer}
                            </td>
                            <td style={{ width: '30%' }}>{user.praxName}</td>
                            <td style={{ width: '15%' }}>
                              {user.patients.length}
                            </td>
                            <td style={{ width: '20%' }}>
                              {prettifyDate(user.lastLogin)}
                            </td>
                            <td style={{ width: '15%' }}>
                              <Link
                                className="btn btn-primary"
                                to={`/benutzerverwaltung/show/${user._id}`}
                              >
                                Öffnen
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default UserList;
