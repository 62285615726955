import { FormType } from '../types/FormTypes';

const validateStep4 = (formValues: FormType): string[] => {
  const errors = [];
  // Benutzername Check
  if (formValues.benutzername.length < 4) {
    errors.push('benutzername');
  }

  // Password Check
  if (formValues.password.length < 8) {
    errors.push('password');
  }
  if (
    !formValues.password.match(
      '^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$',
    )
  ) {
    errors.push('password');
  }

  if (
    formValues.password !== formValues.repPassword ||
    formValues.repPassword.length < 8
  ) {
    errors.push('password');
  }

  if (!formValues.licenseAgreement) {
    errors.push('licenseAgreement');
  }

  return errors;
};

export default validateStep4;
