import { FormType } from '../types/FormTypes';

const validateStep2 = (formValues: FormType): string[] => {
  const errors = [];
  // Vorname Check
  if (formValues.vorname.length < 3) {
    errors.push('vorname');
  }

  // Nachname Check
  if (formValues.nachname.length < 3) {
    errors.push('nachname');
  }

  // Email Check
  if (formValues.email.length < 5) {
    errors.push('email');
  }
  if (
    !formValues.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
    )
  ) {
    errors.push('email');
  }

  // Telefon Check
  if (formValues.telefon.length < 3) {
    errors.push('telefon');
  }

  if (!formValues.telefon.match('^[0-9+-]*$')) {
    errors.push('telefon');
  }

  return errors;
};

export default validateStep2;
