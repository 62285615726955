import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { User } from '@customTypes/User';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import validateUserEdit from '../functions/validateUserEdit';
import submitUserEdit from './submitUserEdit';

type Props = {
  user: User,
  setEditMode: (editMode: boolean) => void,
  updateUser: () => Promise<void>,
};

const UserDetails = ({ user, setEditMode, updateUser }: Props): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [editedUser, setEditedUser] = useState<User>();

  useEffect(() => {
    setEditedUser({ ...user });
  }, [user]);
  const updateEdits = (name: string, value: string | boolean): void => {
    if (name === 'praxScanner' && typeof value === 'boolean') {
      // @ts-ignore
      setEditedUser({
        ...editedUser,
        [name]: value,
      });
    } else if (name === 'patientenIndex' && typeof value === 'string') {
      // @ts-ignore
      setEditedUser({
        ...editedUser,
        patientenIndex: parseInt(value, 10),
      });
    } else {
      // @ts-ignore
      setEditedUser({
        ...editedUser,
        [name]: value,
      });
    }
  };

  const handleSubmitUser = async (): Promise<void> => {
    try {
      if (!editedUser) return;
      const validationResult = validateUserEdit(user, editedUser);
      setValidationErrors(validationResult);
      if (validationResult.length === 0) {
        updateShowLoadingModal(true);
        await submitUserEdit(jwt, editedUser);
        await updateUser();
        setEditMode(false);
        updateShowLoadingModal(false);
      }
    } catch (error) {
      updateShowLoadingModal(false);
      toast.error('Benutzer konnte nicht geändert werden');
    }
  };

  if (!editedUser) return <div />;
  return (
    <div>
      <h4>Informationen (Bearbeiten)</h4>
      <div>
        <Form>
          <Form.Group>
            <Form.Label>Kundennummer</Form.Label>
            <Form.Control
              type="text"
              name="kundennummer"
              value={editedUser.kundennummer}
              onChange={(e): void =>
                updateEdits('kundennummer', e.target.value)
              }
              className={
                validationErrors.includes('kundennummer') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Kundennummer ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Benutzername</Form.Label>
            <Form.Control
              type="text"
              disabled
              name="benutzername"
              value={editedUser.benutzername}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>E-Mail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={editedUser.email}
              onChange={(e): void => updateEdits('email', e.target.value)}
              className={validationErrors.includes('email') ? 'is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige E-Mail ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Telefon</Form.Label>
            <Form.Control
              type="phone"
              name="telefon"
              onChange={(e): void => updateEdits('telefon', e.target.value)}
              value={editedUser.telefon}
              className={
                validationErrors.includes('telefon') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Telefonnummer ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Vorname</Form.Label>
            <Form.Control
              type="text"
              name="vorname"
              onChange={(e): void => updateEdits('vorname', e.target.value)}
              value={editedUser.vorname}
              className={
                validationErrors.includes('vorname') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Telefonnummer ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nachname</Form.Label>
            <Form.Control
              type="text"
              name="nachname"
              onChange={(e): void => updateEdits('nachname', e.target.value)}
              value={editedUser.nachname}
              className={
                validationErrors.includes('nachname') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie einen gültigen Nachnamen ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Praxisname</Form.Label>
            <Form.Control
              type="text"
              name="praxName"
              onChange={(e): void => updateEdits('praxName', e.target.value)}
              value={editedUser.praxName}
              className={
                validationErrors.includes('praxName') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie einen gültigen Praxisnamen ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Praxisstrasse</Form.Label>
            <Form.Control
              type="text"
              name="praxStrasse"
              onChange={(e): void => updateEdits('praxStrasse', e.target.value)}
              value={editedUser.praxStrasse}
              className={
                validationErrors.includes('praxStrasse') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Praxisstrasse ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Praxis PLZ</Form.Label>
            <Form.Control
              type="text"
              name="praxPLZ"
              onChange={(e): void => updateEdits('praxPLZ', e.target.value)}
              value={editedUser.praxPLZ}
              className={
                validationErrors.includes('praxPLZ') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine gültige Praxis PLZ ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Praxis Ort</Form.Label>
            <Form.Control
              type="text"
              name="praxOrt"
              onChange={(e): void => updateEdits('praxOrt', e.target.value)}
              value={editedUser.praxOrt}
              className={
                validationErrors.includes('praxOrt') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie einen gültigen Praxis PLZ ein
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Praxis Scanner</Form.Label>
            <Form.Check
              name="praxScanner"
              onChange={(e): void =>
                updateEdits('praxScanner', e.target.checked)
              }
              checked={editedUser.praxScanner}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Patienten Index</Form.Label>
            <Form.Control
              type="number"
              name="patientenIndex"
              onChange={(e): void =>
                updateEdits('patientenIndex', e.target.value)
              }
              value={editedUser.patientenIndex}
              className={
                validationErrors.includes('patientenIndex') ? 'is-invalid' : ''
              }
            />
            <Form.Control.Feedback type="invalid">
              Der Patientenindex ist ungültig
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </div>
      <h4 className="mt-3">Aktionen</h4>
      <div>
        <button
          type="button"
          className="btn btn-primary m-3"
          onClick={(): void => setEditMode(false)}
        >
          Abbrechen
        </button>
        <button
          type="button"
          className="btn btn-primary m-3"
          onClick={handleSubmitUser}
        >
          Speichern
        </button>
      </div>
    </div>
  );
};

export default UserDetails;
