import axios from 'axios';
import { apiHost } from '../../../../env_vars';

const submitPassword = async (
  jwt: string,
  userId: string,
  password: string,
): Promise<void> => {
  const url = `${apiHost}/user/${userId}`;
  const options = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.patch(url, { newPassword: password }, options);
};

export default submitPassword;
