import React, { useEffect, useState } from 'react';
import './PatientenList.css';
import { Patient } from '@customTypes/Patient';
import useAuthStore from '@/stores/authStore';
import fetchUser from '@/api/fetchUser';
import { Link } from 'react-router-dom';
import { prettifyDate } from '@/useful_functions';
import Sidebar from '@/components/sidebar/Sidebar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const PatientenList = (): JSX.Element => {
  const { jwt, jwtContent } = useAuthStore();
  const [patients, setPatients] = useState<Patient[]>([]);

  useEffect(() => {
    fetchUser(jwtContent.userId, jwt).then((user) => {
      const sortedPatients = user.patients.sort(
        (a, b) =>
          new Date(
            b.statusHistory[b.statusHistory.length - 1].timestamp,
          ).getTime() -
          new Date(
            a.statusHistory[a.statusHistory.length - 1].timestamp,
          ).getTime(),
      );
      setPatients(sortedPatients);
    });
  }, []);

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="patienten" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup>
            <CSSTransition classNames="slider-right" timeout={1000}>
              <div className="patienten-list">
                <div className="d-flex justify-content-between">
                  <h2 className="header">Patienten</h2>
                  <div className="d-flex align-items-center">
                    {jwtContent.userRole === 'viewer' ? (
                      <Link
                        to="/patienten/new"
                        className="ml-auto btn btn-primary"
                      >
                        Hinzufügen
                      </Link>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
                <div>
                  <table id="header-table">
                    <thead className="m-5">
                      <tr className="bold">
                        <td>Nummer</td>
                        <td width="25%">
                          {jwtContent.userRole !== 'viewer'
                            ? 'Zahnarzt'
                            : 'Interne Patientennummer'}
                        </td>
                        <td width="10%">Aktion</td>
                        <td width="25%">Status</td>
                        <td width="20%">Letzte Änderung</td>
                      </tr>
                    </thead>
                  </table>
                  <div id="table-container">
                    <table id="body-table">
                      <tbody>
                        {patients.map((patient) => (
                          <tr
                            className={
                              patient.meta.treatmentFinished ? 'finished' : ''
                            }
                          >
                            <td
                              width="20%"
                              className={
                                (patient.meta.unreadAdmin &&
                                  jwtContent.userRole === 'admin') ||
                                (patient.meta.unread &&
                                  jwtContent.userRole !== 'admin')
                                  ? 'unread'
                                  : ''
                              }
                            >
                              {patient.patientenNummer}
                            </td>
                            <td
                              width="25%"
                              className={
                                (patient.meta.unreadAdmin &&
                                  jwtContent.userRole === 'admin') ||
                                (patient.meta.unread &&
                                  jwtContent.userRole !== 'admin')
                                  ? 'unread'
                                  : ''
                              }
                            >
                              {jwtContent.userRole === 'admin'
                                ? patient.createdBy.praxName
                                : patient.info.intPatientenNummer}
                            </td>
                            <td width="10%">
                              <Link
                                className="btn btn-primary"
                                to={`/patienten/show/${patient._id}`}
                              >
                                Öffnen
                              </Link>
                            </td>
                            <td width="25%">
                              {
                                patient.statusHistory[
                                  patient.statusHistory.length - 1
                                ].message
                              }
                            </td>
                            <td width="20%">
                              {prettifyDate(
                                patient.statusHistory[
                                  patient.statusHistory.length - 1
                                ].timestamp,
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default PatientenList;
