const validateNewPassword = (
  password: string,
  newPassword: string,
  newPasswordRepeat: string,
): string[] => {
  const errors: string[] = [];
  if (password === '') {
    errors.push('password');
  }
  if (
    newPassword === '' ||
    newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)
  ) {
    errors.push('newPassword');
  }
  if (newPasswordRepeat === '') {
    errors.push('newPasswordRepeat');
  }
  if (newPasswordRepeat !== newPassword) {
    errors.push('newPasswordRepeat');
  }
  return errors;
};

export default validateNewPassword;
