import axios from 'axios';
import { apiHost } from '../../../../env_vars';

const fetchCodes = async (id: string, jwt: string): Promise<void> => {
  const url = `${apiHost}/registerKey/${id}`;
  const options = {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.delete(url, options);
};

export default fetchCodes;
