import usePatientInfoStore from '@/stores/patientInfoStore';
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import validatePicture from './functions/validatePicture';
import submitImage from './functions/submitImage';

const UploadPictureModal = (): JSX.Element => {
  const { imageUploadModalInfo, updateImageUploadModalInfo, reloadPatient } =
    usePatientInfoStore();
  const { jwt, resetLoadingModal } = useAuthStore();
  const { patient } = usePatientInfoStore();
  const [selectedPicture, setSelectedPicture] = useState<File>();
  const [imagePreview, setImagePreview] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = (): void => {
    updateImageUploadModalInfo({
      show: false,
      imageKey: '',
      imageName: '',
    });
    setSelectedPicture(undefined);
    setImagePreview('');
    setErrorMessage('');
  };

  const changeImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return;
    setSelectedPicture(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const resetImage = (): void => {
    setSelectedPicture(undefined);
    setImagePreview('');
    setErrorMessage('');
  };

  const processSubmitImage = (): void => {
    // No image selected
    if (!selectedPicture) {
      setErrorMessage('Bitte wählen Sie ein Bild aus');
      return;
    }

    // If we have an validation error, set the error message and return
    const validationError = validatePicture(selectedPicture);
    if (validationError) {
      setErrorMessage(errorMessage);
      return;
    }

    // Fail if patient is not loaded
    if (!patient) {
      toast.error('Es ist ein Fehler aufgetreten');
      return;
    }

    closeModal();

    // Submit the image
    submitImage(
      jwt,
      patient._id,
      selectedPicture,
      imageUploadModalInfo.imageKey,
    )
      .then(() => {
        reloadPatient(jwt, patient._id);
        toast.success('Bild wurde hochgeladen');
      })
      .catch(() => toast.error('Es ist ein Fehler aufgetreten'))
      .finally(() => resetLoadingModal());
  };
  return (
    <Modal
      show={imageUploadModalInfo.show}
      onBackdropClick={closeModal}
      backdrop="static"
      className=""
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h3>Bild Hochladen</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3">
          <p>{imageUploadModalInfo.imageName}</p>
          <div className="text-center">
            <img
              className="img-fluid rounded"
              src={`/samplePictures/${imageUploadModalInfo.imageKey}.jpg`}
              alt="Beispiel-Bild"
              style={{ maxWidth: '400px' }}
            />
          </div>
        </div>
        <div className="d-flex m-4 justify-content-center">
          <div className="text-center">
            {selectedPicture ? (
              <div className="">
                <h5 className="text-center">Ihr ausgewähltes Bild:</h5>
                <div className="text-center">
                  <img
                    className="img-fluid"
                    alt="Datei nicht lesbar, bitte erneut auswählen"
                    src={imagePreview}
                    style={{ maxWidth: '400px' }}
                  />
                </div>
                <Button type="button" className="my-2" onClick={resetImage}>
                  Bild Wechseln
                </Button>
              </div>
            ) : (
              <Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Bild Hochladen</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={changeImage}
                  />
                </Form.Group>
              </Form.Group>
            )}
          </div>
        </div>
        {errorMessage !== '' && (
          <div
            className="bg-hint-alert text-center mx-4 px-4 py-2 my-3"
            style={{ maxWidth: '500px' }}
          >
            <h5>{errorMessage}</h5>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          Zurück
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={processSubmitImage}
        >
          Hochladen
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadPictureModal;
