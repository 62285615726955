import usePatientInfoStore from '@/stores/patientInfoStore';
import { prettifyFileSize } from '@/useful_functions';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import useAuthStore from '@/stores/authStore';
import { toast } from 'react-toastify';
import validateSTLFiles from './functions/validateSTLFiles';
import postSTLFiles from './functions/postSTLFiles';

const UploadStlModal = (): JSX.Element => {
  const { jwt, updateShowLoadingModal } = useAuthStore();
  const {
    showUploadSTLModal,
    updateShowUploadSTLModal,
    patient,
    reloadPatient,
  } = usePatientInfoStore();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [hintText, setHintText] = useState<string>('');

  const closeForm = (): void => {
    setHintText('');
    setSelectedFiles([]);
    updateShowUploadSTLModal(false);
  };

  const submitFiles = async (): Promise<void> => {
    try {
      const valResult = validateSTLFiles(selectedFiles);
      setHintText(valResult);
      // If there are error messages, return
      if (valResult !== '') return;

      // If there is no patient, return
      if (!patient) return;
      updateShowLoadingModal(true);
      await postSTLFiles(jwt, patient._id, selectedFiles);
      closeForm();
      toast.success('STL Dateien erfolgreich hochgeladen');
      reloadPatient(jwt, patient._id);
      updateShowLoadingModal(false);
    } catch (e) {
      toast.error('Fehler beim Hochladen der STL Dateien');
    }
  };

  return (
    <Modal show={showUploadSTLModal} backdrop="static" className="" centered>
      <Modal.Header>
        <Modal.Title>
          <h3>STL Datei Hochladen </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3">
          <p>
            Bitte Laden sie Ihre STL Datei hoch. Es werden keine ZIP oder RAR
            Dateien akzeptiert, nur STL Dateien.
          </p>
        </div>
        <div className="d-flex mx-4 my-2">
          <div>
            <Form.Group>
              <Form.Label>STL Datei Hochladen</Form.Label>
              <Form.Control
                type="file"
                accept=".stl"
                multiple
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e.target.files) {
                    setSelectedFiles(Array.from(e.target.files));
                  }
                }}
              />
            </Form.Group>
          </div>
        </div>
        {selectedFiles.length > 0 && (
          <div className="px-4">
            <span>
              <b>Ausgewählte Dateien</b>
            </span>
            {selectedFiles.map((file) => (
              <div>
                {file.name} - Grösse:
                {prettifyFileSize(file.size)}
              </div>
            ))}
          </div>
        )}
        {hintText !== '' && (
          <div
            className="bg-hint-alert text-center mx-4 px-4 py-2 my-3"
            style={{ maxWidth: '500px' }}
          >
            <h5>{hintText}</h5>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" type="button" onClick={closeForm}>
          Zurück
        </button>
        <button type="button" className="btn btn-primary" onClick={submitFiles}>
          Hochladen
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadStlModal;
