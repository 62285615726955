import { apiHost } from '@/env_vars';
import axios from 'axios';

const submitImage = async (
  jwt: string,
  _id: string,
  imageName: string,
  file: File,
): Promise<void> => {
  const data = new FormData();
  data.append('_id', _id);
  data.append('imagePosition', imageName);
  data.append('file', file);
  const options = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${jwt}`,
    },
  };
  await axios.post(`${apiHost}/imageUpload`, data, options);
};

export default submitImage;
