import React from 'react';

type Props = {
  submit: () => void,
};

const SubmitButton = ({ submit }: Props): JSX.Element => (
  <button
    className="login-button prevnext-button float-right"
    type="button"
    onClick={submit}
  >
    Abschicken
  </button>
);

export default SubmitButton;
