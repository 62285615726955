import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import './ModalLoadingScreen.css';

type Props = {
  loading: boolean,
  progressBar?: boolean,
  progressBarPercent?: number,
  progressBarText?: string,
};

const ModalLoadingScreen = ({
  loading,
  progressBar = false,
  progressBarPercent = 0,
  progressBarText = '',
}: Props): JSX.Element => (
  <Modal show={loading} backdrop="static" className="loading-modal" centered>
    <div className="loading-container">
      <div className="top-loading-container">
        <img src="/loadingPictures/loading.gif" alt="Loading Spinner" />
      </div>
      <div className="bottom-loading-container">
        {progressBar ? (
          <div>
            <ProgressBar className="my-3">
              <ProgressBar
                striped
                variant="info"
                animated
                now={progressBarPercent}
              />
            </ProgressBar>
            <h5 className="text-center py-3">
              {progressBarPercent} %
              <br />
              {progressBarText}
            </h5>
          </div>
        ) : (
          <h4>Laden...</h4>
        )}
      </div>
    </div>
  </Modal>
);

export default ModalLoadingScreen;
