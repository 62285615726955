import { apiHost } from '../../../../env_vars';

/*
    StillLoggedIn()
    Is a short function to check if our JWT exists and is valid
*/
const postMessage = async (
  patientId: string,
  message: string,
  jwt: string,
): Promise<void> => {
  const url = `${apiHost}/messages/${patientId}`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify({
      message,
    }),
  };
  await fetch(url, options);
};

export default postMessage;
