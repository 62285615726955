import React from 'react';
import useNewPatientStore from '@/stores/newPatientStore';
import Sidebar from '@/components/sidebar/Sidebar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import TreatmentType from './steps/TreatmentType/TreatmentType';
import PictureStep from './steps/PictureStep/PictureStep';
import './PatientenNew.css';
import Summary from './steps/Summary/Summary';
import GeneralInformation from './steps/GeneralInformation/GeneralInformation';

const PatientenNew = (): JSX.Element => {
  const { currentStep } = useNewPatientStore();
  return (
    <div className="d-flex" id="wrapper">
      <Sidebar activeItem="patienten" />
      <div className="p-5" id="page-content-wrapper">
        <div style={{ minWidth: '1000px' }}>
          <TransitionGroup
            className={`new-patient-container ${currentStep !== 13 && 'h-100'}`}
          >
            {currentStep === 1 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <GeneralInformation />
              </CSSTransition>
            )}
            {currentStep === 2 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <TreatmentType />
              </CSSTransition>
            )}

            {/* Start of Picture Sequence */}
            {currentStep === 3 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="frontalSchlussbiss" />
              </CSSTransition>
            )}
            {currentStep === 4 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="lateralLinks" />
              </CSSTransition>
            )}
            {currentStep === 5 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="lateralRechts" />
              </CSSTransition>
            )}
            {currentStep === 6 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="oberkiefer" />
              </CSSTransition>
            )}
            {currentStep === 7 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="unterkiefer" />
              </CSSTransition>
            )}
            {currentStep === 8 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="gesichtGeschlossen" />
              </CSSTransition>
            )}
            {currentStep === 9 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="gesichtLachen" />
              </CSSTransition>
            )}
            {currentStep === 10 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="lateralGesicht" />
              </CSSTransition>
            )}
            {currentStep === 11 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <PictureStep pictureName="panorama" />
              </CSSTransition>
            )}
            {/* End of Picture Sequence */}
            {currentStep === 12 && (
              <CSSTransition classNames="slider-right" timeout={1000}>
                <Summary />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default PatientenNew;
