export type FormType = {
  verifCode: string,
  vorname: string,
  nachname: string,
  email: string,
  telefon: string,
  praxName: string,
  praxStrasse: string,
  praxPLZ: string,
  praxOrt: string,
  benutzername: string,
  password: string,
  repPassword: string,
  licenseAgreement: boolean,
  praxScanner: string,
};

export const emptyForm = {
  verifCode: '',
  vorname: '',
  nachname: '',
  email: '',
  telefon: '',
  praxName: '',
  praxStrasse: '',
  praxPLZ: '',
  praxOrt: '',
  benutzername: '',
  password: '',
  repPassword: '',
  licenseAgreement: false,
  praxScanner: '',
};
