import React from 'react';
import usePatientInfoStore from '@/stores/patientInfoStore';

const DentworryInstructionsButton = (): JSX.Element | undefined => {
  const { patient } = usePatientInfoStore();

  // if we dont have a patient, we dont render anything
  if (!patient) return undefined;

  if (
    !patient.meta.stlUploaded &&
    !patient.meta.homeScan &&
    patient.meta.caseTreatable
  ) {
    return (
      <button
        type="button"
        className="btn btn-primary mx-2"
        onClick={(): void => {}}
      >
        Dentworry Anleitung
      </button>
    );
  }
  return undefined;
};

export default DentworryInstructionsButton;
