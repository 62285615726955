import { apiHost } from '@/env_vars';
import axios from 'axios';

const postAuftragsfreigabe = async (
  jwt: string,
  patientId: string,
): Promise<void> => {
  const url = `${apiHost}/patient/${patientId}/status`;
  await axios.post(
    url,
    { status: 'auftragsFreigabeAnimation' },
    { headers: { Authorization: `Bearer ${jwt}` } },
  );
};

export default postAuftragsfreigabe;
