import React, { useEffect, useState } from 'react';
import {
  BsFillGridFill,
  BsFillPeopleFill,
  BsFillInboxFill,
  BsFillGearFill,
  BsBoxArrowInRight,
  BsFillLockFill,
  BsFillPersonLinesFill,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import fetchUser from '@/api/fetchUser';
import useAuthStore from '../../stores/authStore';

type SidebarProps = {
  activeItem: string,
};

const Sidebar = ({ activeItem }: SidebarProps): JSX.Element => {
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const { logout, jwtContent, jwt } = useAuthStore();

  useEffect(() => {
    // fetch user and count unread messages
    fetchUser(jwtContent.userId, jwt).then((user) => {
      let unreadMessagesCount = 0;
      // We iterate through all patients and check if there are unread messages
      user.patients.forEach((patient) => {
        const unread =
          (patient.meta.unreadConversation &&
            jwtContent.userRole === 'viewer') ||
          (patient.meta.unreadConversationAdmin &&
            jwtContent.userRole === 'admin');
        if (unread) unreadMessagesCount += 1;
      });
      // Update unread messages count state
      setUnreadMessages(unreadMessagesCount);
    });
  }, []);
  return (
    <div className="bg-light-gray" id="sidebar-wrapper">
      <div id="sidebar-heading">
        <img src="/assets/logo.png" alt="logo" id="sidebar-logo" />
      </div>
      <div className="list-group list-group-flush">
        <Link
          to="/"
          className="sidebar-list-item"
          data-active={activeItem === 'dashboard' ? 'true' : 'false'}
        >
          <BsFillGridFill /> Dashboard
        </Link>
        <Link
          to="/patienten"
          className="sidebar-list-item"
          data-active={activeItem === 'patienten' ? 'true' : 'false'}
        >
          <BsFillPeopleFill /> Patienten
        </Link>
        {jwtContent.userRole !== 'dentworry' && (
          <Link
            to="/nachrichten"
            className="sidebar-list-item d-flex justify-content-between"
            data-active={activeItem === 'nachrichten' ? 'true' : 'false'}
          >
            <div className={unreadMessages > 0 ? 'bold' : ''}>
              <BsFillInboxFill /> Nachrichten{' '}
            </div>
            {unreadMessages > 0 && (
              <div className="unread-messages-container bg-danger rounded-circle">
                <span className="unread-messages-text text-white bold">
                  {unreadMessages}
                </span>
              </div>
            )}
          </Link>
        )}
        {jwtContent.userRole === 'admin' && (
          <Link
            to="/verifcodes"
            className="sidebar-list-item"
            data-active={activeItem === 'verifCodes' ? 'true' : 'false'}
          >
            <BsFillLockFill /> Verifizierungscodes
          </Link>
        )}
        {jwtContent.userRole === 'admin' && (
          <Link
            to="/benutzerverwaltung"
            className="sidebar-list-item"
            data-active={activeItem === 'benutzerverwaltung' ? 'true' : 'false'}
          >
            <BsFillPersonLinesFill /> Benutzerverwaltung
          </Link>
        )}
        <Link
          to="/einstellungen"
          className="sidebar-list-item"
          data-active={activeItem === 'einstellungen' ? 'true' : 'false'}
        >
          <BsFillGearFill /> Einstellungen
        </Link>
      </div>
      <div className="sidebar-footer">
        <div className="row d-flex align-items-center mx-0">
          <div className="col-8">
            Eingeloggt als:{' '}
            <span className="footer-user">{jwtContent.userName}</span>
          </div>
          <div className="col-4 pl-4 pt-2">
            <h3 className="logout-button" onClick={(): void => logout()}>
              <BsBoxArrowInRight />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
