/*
    StillLoggedIn()
    Is a short function to check if our JWT exists and is valid
*/
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { apiHost } from './env_vars';

type CookieContent = {
  _id: string,
  userRole: string,
  userName: string,
};

type JWTwithContent = {
  jwtContent: CookieContent,
  jwt: string,
};

const stillLoggedIn = async (): Promise<JWTwithContent> => {
  const cookie = new Cookies();
  const smileToken = cookie.get('smile-token');
  if (smileToken === undefined || smileToken === null) {
    throw new Error('NO_AUTH');
  }
  const url = `${apiHost}/jwt`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${smileToken}`,
    },
  };
  const response = await fetch(url, options);
  if (response.status !== 200 && response.status !== 204) {
    cookie.remove('smile-token');
    throw new Error('INACTIVITY');
  }
  // Token is valid so we parse it
  const decodedJWT = jwtDecode<CookieContent>(smileToken);

  // We receieved a Fresher Token
  if (response.status === 200) {
    const parsedResponse = await response.json();
    cookie.set('smile-token', parsedResponse.refreshToken, {
      path: '/',
      secure: true,
    });
  }
  return { jwt: smileToken, jwtContent: decodedJWT };
};

export default stillLoggedIn;
