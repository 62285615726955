const validatePicture = (selectedPicture: File): void | string => {
  if (
    selectedPicture.type !== 'image/png' &&
    selectedPicture.type !== 'image/jpeg'
  ) {
    return 'Unbekanntes Dateiformat, bitte wählen sie eine JPEG oder PNG Datei aus !';
  }
  if (selectedPicture.size >= 10000000) {
    return 'Die Datei ist zu groß, bitte stellen sie sicher dass die maximale Dateigrösse von 10MB nicht überschritten wurde';
  }
  return undefined;
};

export default validatePicture;
