import React, { useEffect, useState } from 'react';
import './EinstellungenMain.css';
import useAuthStore from '@/stores/authStore';
import fetchUser from '@/api/fetchUser';
import { toast } from 'react-toastify';
import { User } from '@customTypes/User';
import { prettifyDate } from '../../../useful_functions';

type Props = {
  changeContainer: (container: string) => void,
};

const EinstellungenMain = ({ changeContainer }: Props): JSX.Element => {
  const { jwt, jwtContent } = useAuthStore();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    try {
      fetchUser(jwtContent.userId, jwt).then((fetchedUser) => {
        setUser(fetchedUser);
      });
    } catch (error) {
      toast.error('Fehler beim Laden der Daten');
    }
  }, []);

  if (!user) return <div>Loading...</div>;

  return (
    <div>
      <div className="row">
        <div className="col-4 einstellungen-box">
          <h2 className="header">Einstellungen</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-6  my-3 einstellungen-box">
          <div>
            <h5>Informationen</h5>
            <p>
              {' '}
              <b>Kundennummer:</b> {user.kundennummer}{' '}
            </p>
            <p>
              <b>Praxisadresse:</b> <br /> {user.praxName} <br />
              {user.praxStrasse}
              <br /> {user.praxPLZ} {user.praxOrt}
            </p>
            <p>
              {' '}
              <b>Benutzername:</b> {user.benutzername}{' '}
            </p>
            <p>
              {' '}
              <b>E-Mail Adresse:</b> {user.email}
            </p>
            <p>
              {' '}
              <b>Telefon:</b> {user.telefon}
            </p>
            <p>
              {' '}
              <b>Scanart:</b>{' '}
              {user.praxScanner ? 'Eigener 3D Scan' : 'Modelle / Abdrücke'}
            </p>
            <p>
              <b>Registriert Seit:</b> {prettifyDate(user.dateCreated, true)}
            </p>
            <span className="hint">
              Sollten diese Informationen nicht stimmen, kontaktieren sie bitte
              uns
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 einstellungen-box">
          <div>
            <h5>Passwort:</h5>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(): void => changeContainer('password')}
            >
              Passwort Ändern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EinstellungenMain;
